import React, { Component} from 'react';
import { connect } from 'react-redux';
import * as Actions from "../../store/actions";
import './Filter.css';


const colorGrades = ["#a355d3", "#eea3ff", "#e1d4e7"];
class RegularFilters extends Component {
  componentDidMount() {
    this.props.resetSelected();
  }
  render() {
    let filterView = this.props.filters.map((filter, index) => {
      return (
        <div className={'filter-title'} style={{
              display: 'flex',
              flexDirection: 'column',
              cursor: 'crosshair',
              paddingRight: '14px',
              paddingLeft: '10px',
              opacity: this.props.selected[index].isActive? 1: 0.8,

            }} key={index}
            onClick={()=> {
              this.props.toggleSelected(index);
              }}>
            <div className={'filter-title'} style={{
              opacity: this.props.selected[index].isActive? 1: 0.5,
              fontFamily: "Miriam Libre",
              fontWeight: 'bold',
              fontSize: '12px',
              color: colorGrades[index]}}>
              {filter.h}
            </div>
            <div className={['filter-title','rus-font'].join(" ")} style={{
              opacity: this.props.selected[index].isActive? 1: 0.5,
              fontWeight: 500,
              fontSize: '12px',
              color: colorGrades[index]}}>
                {filter.r}
            </div>
      </div>);
    })
    return (
      <div style={{
            display: 'flex',
            flexDirection: 'row',
            cursor: 'crosshair'
          }}>
          {filterView}
      </div>
  )
  }
}


const mapStateToProps = state => {
  return {
    selected: state.selectedFilter,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleSelected: (index) => dispatch({type: Actions.TOGGLE_FILTERS_SELECTED, index: index}),
    resetSelected: (isShown) => dispatch({type: Actions.RESET_FILTERS_SELECTED})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegularFilters);
