import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import Layout from './containers/Layout/Layout';
import store from './store/store';


function App() {
  var w = window.innerWidth;
  var h = window.innerHeight;
  console.log(w);

  return (
    <Provider store={store}>
      <BrowserRouter>

      <div className="App">
        {w>1345?<Layout/>:<div style={{fontSize: '16px', color: '#ece5d9', width: '100%', textAlign: 'center', paddingTop: '24px'}}>
          אופס! גודל המסך לא נתמך באתר זה. נסה לפתוח את האתר במחשב
          </div>}
      </div>


      </BrowserRouter>
    </Provider>
  );
}

export default App;
