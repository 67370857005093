import React, { Component} from 'react';

import './Filter.css';

import Icon1 from '../../assets/filters/1.png';
import Icon2 from '../../assets/filters/2.png';
import Icon3 from '../../assets/filters/3.png';
import Icon4 from '../../assets/filters/4.png';
import Icon5 from '../../assets/filters/5.png';
import Icon6 from '../../assets/filters/6.png';
import Icon7 from '../../assets/filters/7.png';
import Icon8 from '../../assets/filters/8.png';
import Icon9 from '../../assets/filters/9.png';
import Icon10 from '../../assets/filters/10.png';
import Icon11 from '../../assets/filters/11.png';
import Icon12 from '../../assets/filters/12.png';
import Icon13 from '../../assets/filters/13.png';

import Icon1_e from '../../assets/filters/1_e.png';
import Icon2_e from '../../assets/filters/2_e.png';
import Icon3_e from '../../assets/filters/3_e.png';
import Icon4_e from '../../assets/filters/4_e.png';
import Icon5_e from '../../assets/filters/5_e.png';
import Icon6_e from '../../assets/filters/6_e.png';
import Icon7_e from '../../assets/filters/7_e.png';
import Icon8_e from '../../assets/filters/8_e.png';
import Icon9_e from '../../assets/filters/9_e.png';
import Icon10_e from '../../assets/filters/10_e.png';
import Icon11_e from '../../assets/filters/11_e.png';
import Icon12_e from '../../assets/filters/12_e.png';
import Icon13_e from '../../assets/filters/13_e.png';
import { connect } from 'react-redux';
import * as Actions from "../../store/actions";

const iconWidth = '100%';
const iconHeight = 'auto';

const icons = [
    <img src={Icon3} alt="filter3" width={iconWidth} height={iconHeight}/>,
    <img src={Icon1} alt="filter1" width={iconWidth} height={iconHeight}/>,
    <img src={Icon2} alt="filter2" width={iconWidth} height={iconHeight}/>,
    <img src={Icon4} alt="filter4" width={iconWidth} height={iconHeight}/>,
    <img src={Icon5} alt="filter5" width={iconWidth} height={iconHeight}/>,
    <img src={Icon6} alt="filter6" width={iconWidth} height={iconHeight}/>,
    <img src={Icon7} alt="filter7" width={iconWidth} height={iconHeight}/>,
    <img src={Icon8} alt="filter8" width={iconWidth} height={iconHeight}/>,
    <img src={Icon9} alt="filter9" width={iconWidth} height={iconHeight}/>,
    <img src={Icon10} alt="filter10" width={iconWidth} height={iconHeight}/>,
    <img src={Icon11} alt="filter11" width={iconWidth} height={iconHeight}/>,
    <img src={Icon12} alt="filter12" width={iconWidth} height={iconHeight}/>,
    <img src={Icon13} alt="filter13" width={iconWidth} height={iconHeight}/>,

    <img src={Icon3_e} alt="filter3_e" width={iconWidth} height={iconHeight}/>,
    <img src={Icon1_e} alt="filter1_e" width={iconWidth} height={iconHeight}/>,
    <img src={Icon2_e} alt="filter2_e" width={iconWidth} height={iconHeight}/>,
    <img src={Icon4_e} alt="filter4_e" width={iconWidth} height={iconHeight}/>,
    <img src={Icon5_e} alt="filter5_e" width={iconWidth} height={iconHeight}/>,
    <img src={Icon6_e} alt="filter6_e" width={iconWidth} height={iconHeight}/>,
    <img src={Icon7_e} alt="filter7_e" width={iconWidth} height={iconHeight}/>,
    <img src={Icon8_e} alt="filter8_e" width={iconWidth} height={iconHeight}/>,
    <img src={Icon9_e} alt="filter9_e" width={iconWidth} height={iconHeight}/>,
    <img src={Icon10_e} alt="filter10_e" width={iconWidth} height={iconHeight}/>,
    <img src={Icon11_e} alt="filter11_e" width={iconWidth} height={iconHeight}/>,
    <img src={Icon12_e} alt="filter12_e" width={iconWidth} height={iconHeight}/>,
    <img src={Icon13_e} alt="filter13_e" width={iconWidth} height={iconHeight}/>,
]

const colorGrades = ["#a355d3", "#eea3ff", "#e1d4e7"];
class SpecialFilters extends Component {
  componentDidMount() {
    this.props.resetSelected();
  }


  render() {
    let filterView = this.props.filters.map((filter, index) => {
      return (
        <div className={'filter-title'} key={index} style={{
              display: 'flex',
              flexDirection: 'row',
              cursor: 'crosshair',
              paddingLeft: '0.5vw'
            }}
            onClick={()=> {
              this.props.toggleSelected(index);
              }}>
                <div style={{     
                  height: '100%',             
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                  }}>
                    <div style={{
                      width: '1vw', 
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                      }}>
                      {this.props.selected[index].isActive ? icons[index] : icons[index + 13]}
                    </div>
                </div>
               
            
        <div className={'filter-title'} style={{
              opacity: this.props.selected[index].isActive? 1:0.4,
              display: 'flex',
              flexDirection: 'column',
              paddingRight: '0.35vw',
              paddingLeft: '0.6dvw',
            }}>
            <div className={'filter-title'} style={{
              fontFamily: "Miriam Libre",
              fontWeight: 'bold',
              fontSize: '0.625vw',
              color: '#ece5d9',
              whiteSpace: 'nowrap'}}>
              {filter.h}
            </div>
            <div className={['filter-title','rus-font'].join(" ")} style={{
              fontWeight: 500,
              fontSize: '0.625vw',
              color: '#ece5d9',
              whiteSpace: 'nowrap'}}>
                {filter.r}
            </div>
      </div>
    </div>);
    });
    return (
      <div style={{
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer'
          }}>
          {filterView}
      </div>

  )
  }
}



const mapStateToProps = state => {
  return {
    selected: state.selectedFilter,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleSelected: (index) => dispatch({type: Actions.TOGGLE_FILTERS_SELECTED, index: index}),
    resetSelected: (isShown) => dispatch({type: Actions.RESET_FILTERS_SELECTED})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialFilters);
