import React, { Component} from 'react';
import './info.css';

export default class Info extends Component {
    render() {
        return (
        <div dir={'rtl'} style={{position: 'fixed', zIndex: 1000, width: '100%', height: '100%', backgroundColor: 'rgba(25, 7, 7, 0.97)',  display: 'flex', flexDirection: 'row'}}>

            <div style={{marginTop: '34.4vh',
            marginRight: '12vw',
            width: '24.8vw',
            height: '14.4vh',
          fontFamily: 'FrankG',
          fontSize: '1.045vw',
          lineHeight: 1.55,
          letterSpacing: 'normal',
          textAlign: 'right',
          color: '#ece5d9',
        }}>
                בגיל שתיים עשרה, נתבקשתי לכתוב עבודת שורשים בבית הספר.<br/> אמי הכינה אותה - כתבה, עיצבה ועימדה - ואילו אני, לא לקחתי חלק בהכנתה, שכן היא סימלה עבורי מבוכה ובושה במוצאי, כבת לעולים מחבר העמים. בפרוייקט הגמר, אני שבה אל עבודת השורשים ההיא מחדש, כאקט של פיוס עם עצמי.
            </div>
            <div style={{marginTop: '34.4vh', width: '21.9vw', height: '31.1vh',
        marginRight: '6.1vw',
        width: '21.9vw',
        height: '336px',
        fontFamily: 'Miriam Libre',
        fontSize: '0.85vw',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.75,
        letterSpacing: 'normal',
        textAlign: 'right',
        color: '#ece5d9',
        }}>
            בעולם טכנולוגי המתפתח במהירות, הדרכים לשימור, איסוף ותיעוד הן שונות ומגוונות. האתר הינו הצעה לארכיון אינטראקטיבי, אשר מכיל הן את התוכן, המסמכים והעובדות והן את הרגשות והמטענים המלווים אותם. ארכיון שהוא חומרי ורגשי.
            <br/><br/>
            האתר מציע מיפוי DNA תרבותי-משפחתי, מתוך ההבנה שכל משפחה היא פולקלור, גוף של ידע, מנהגים ומסורות העוברים מדור לדור, ומשתנים כל הזמן. המיפוי מורכב מעשרים ושלוש ״תרכובות״ משפחתיות. כל תרכובת, מורכבת מחמש קטגוריות פולקלור מרכזיות:  מסורות, אמונות, נרטיבים, אומנויות ושפה, ומשלבת את הצדדים המשפחתיים של שני ההורים. מדדי כל תרכובת הם אינדיבידואליים, ומכאן, שלכל משפחה יהיה מיפוי
            </div>
            <div style={{marginTop: '34.4vh', width: '21.9vw', height: '31.1vh',
        marginRight: '1.1vw',
        width: '21.9vw',
        height: '336px',
        fontFamily: 'Miriam Libre',
        fontSize: '0.85vw',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.75,
        letterSpacing: 'normal',
        textAlign: 'right',
        color: '#ece5d9',
        }}>
            שונה, ומבחינת כל בן או בת משפחה, המיפוי גם כן, יכול להשתנות. אין הגדרה חד משמעית קבועה, למשפחה, לזהות ולמוצא. עץ המשפחה הינו דינמי ובתנועה כל הזמן.
            <br/><br/>
            לחיצה על תרכובת, מובילה לפירוט המרכיבים של אותה תרכובת, שהם תתי הנושאים של קטגוריות הפולקלור. בחלק מן התרכובות, מחכות הפתעות, שהן תגובות ופרשנויות אישיות של יוצר/ת המיפוי לתרכובת. בשביל להגיע אליהן, חפשו את האייקונים המאויירים, בפינה השמאלית העליונה של כל עמוד. בנוסף, בתפריט הפילטרים שבמסך הראשי, ניתן לראות ויזואליזיציות משפחתיות נוספות, וחלוקה לפי תתי הנושאים של הפולקלור, אשר מספקים מידע נוסף על מיפוי ה-DNA של אותה משפחה.
            </div>



        </div>)
    }
}