import React, { Component} from 'react';
import Description from '../../components/Description/Description';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './Landpage.css';
import DNA from '../../components/DNA/DNA';
import * as Actions from "../../store/actions";


const DNA_HEIGHT = 368;




class Landpage extends Component {
  state = {
    tradition: [],
    beliefs: [],
    narrative: [],
    arts: [],
    language: [],
    showOnly: 'all'
  }
  componentDidMount() {
    document.body.style.backgroundColor = 'rgba(0, 0, 0, 0.9)';
    this.props.setHover('', '');
    this.props.setMenu("landpage", ()=>{
      this.props.setShowFilterMenu(!this.props.isShowMenu);
    });

  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isShowMenu !== this.props.isShowMenu) {
      this.props.setMenu(this.props.isShowMenu? "exit":"landpage", ()=>{
          this.props.setShowFilterMenu(!this.props.isShowMenu);
      });
    }

  }


  render() {

    return(
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '10.3vh',
        paddingLeft: '4.5vw',
        marginRight: '4.3vw'
      }}>


      <div style={{
      display: 'flex',
      flexDirection: 'row',
      height: 'calc(36.4vh - 0px)'
      }}>

      <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
      to={'1'}
      onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('1');
        }
        this.props.setShowFilterMenu(false);
      }}
      className={this.props.isShowMenu? 'dnaHovered':'dna'}
      onMouseEnter={()=>{
        this.props.setHover(this.props.items[0].headline);
      }}
      onMouseLeave={()=>{
        this.props.setHover('', '')
      }}
      >
      <DNA history={this.props.history}
      number={'01'}
      {...this.props.items[0]}
      showOnly={this.state.showOnly}
      />
      </div>

      <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
      to={'2'}
      onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('2');
        }
        this.props.setShowFilterMenu(false);
      }}
      className={this.props.isShowMenu? 'dnaHovered':'dna'}
      onMouseEnter={()=>{
        this.props.setHover(this.props.items[1].headline);
      }}
      onMouseLeave={()=>{this.props.setHover('', '')}}
      >
      <DNA history={this.props.history}
      number={'02'}
      {...this.props.items[1]}
      showOnly={this.state.showOnly}
      />
      </div>

      <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
      to={'3'}
      onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('3');
        }
        this.props.setShowFilterMenu(false);
      }}
      className={this.props.isShowMenu? 'dnaHovered':'dna'}
      onMouseEnter={()=>{
        this.props.setHover(this.props.items[2].headline);
      }}
      onMouseLeave={()=>{this.props.setHover('', '')}}
      >
      <DNA history={this.props.history}
      number={'03'}
      {...this.props.items[2]}
      showOnly={this.state.showOnly}
      />
      </div>

      <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
      to={'4'}
      onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('4');
        }
        this.props.setShowFilterMenu(false);
      }}
      className={this.props.isShowMenu? 'dnaHovered':'dna'}
      onMouseEnter={()=>{
        this.props.setHover(this.props.items[3].headline);
      }}
      onMouseLeave={()=>{this.props.setHover('', '')}}
      >
      <DNA history={this.props.history}
      number={'04'}
      {...this.props.items[3]}
      showOnly={this.state.showOnly}
      />
      </div>

      <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
      to={'5'}
      onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('5');
        }
        this.props.setShowFilterMenu(false);
      }}
      className={this.props.isShowMenu? 'dnaHovered':'dna'}
      onMouseEnter={()=>{
        this.props.setHover(this.props.items[4].headline);
      }}
      onMouseLeave={()=>{this.props.setHover('', '')}}
      >
      <DNA history={this.props.history}
      number={'05'}
      {...this.props.items[4]}
      showOnly={this.state.showOnly}
      />
      </div>

      <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
      to={'6'}
      onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('6');
        }
        this.props.setShowFilterMenu(false);
      }}
      className={this.props.isShowMenu? 'dnaHovered':'dna'}
      onMouseEnter={()=>{
        this.props.setHover(this.props.items[5].headline);
      }}
      onMouseLeave={()=>{this.props.setHover('', '')}}
      >
      <DNA history={this.props.history}
      number={'06'}
      {...this.props.items[5]}
      showOnly={this.state.showOnly}
      />
      </div>

      <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
      to={'7'}
      onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('7');
        }
        this.props.setShowFilterMenu(false);
      }}
      className={this.props.isShowMenu? 'dnaHovered':'dna'}
      onMouseEnter={()=>{
        this.props.setHover(this.props.items[6].headline);
      }}
      onMouseLeave={()=>{this.props.setHover('', '')}}
      >
      <DNA history={this.props.history}
      number={'07'}
      {...this.props.items[6]}
      showOnly={this.state.showOnly}
      />
      </div>

      <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
      to={'8'}
      onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('8');
        }
        this.props.setShowFilterMenu(false);
      }}
      className={this.props.isShowMenu? 'dnaHovered':'dna'}
      onMouseEnter={()=>{
        this.props.setHover(this.props.items[7].headline);
      }}
      onMouseLeave={()=>{this.props.setHover('', '')}}
      >
      <DNA history={this.props.history}
      number={'08'}
      {...this.props.items[7]}
      showOnly={this.state.showOnly}
      />
      </div>

      <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
      to={'9'}
      onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('9');
        }
        this.props.setShowFilterMenu(false);
      }}
      className={this.props.isShowMenu? 'dnaHovered':'dna'}
      onMouseEnter={()=>{
        this.props.setHover(this.props.items[8].headline);
      }}
      onMouseLeave={()=>{this.props.setHover('', '')}}
      >
      <DNA history={this.props.history}
      number={'09'}
      {...this.props.items[8]}
      showOnly={this.state.showOnly}
      />
      </div>

      <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
      to={'10'}
      onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('10');
        }
        this.props.setShowFilterMenu(false);
      }}
      className={this.props.isShowMenu? 'dnaHovered':'dna'}
      onMouseEnter={()=>{
        this.props.setHover(this.props.items[9].headline);
      }}
      onMouseLeave={()=>{this.props.setHover('', '')}}
      >
      <DNA history={this.props.history}
      number={'10'}
      {...this.props.items[9]}
      showOnly={this.state.showOnly}
      />
      </div>

      <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
      to={'11'}
      onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('11');
        }
        this.props.setShowFilterMenu(false);
      }}
      className={this.props.isShowMenu? 'dnaHovered':'dna'}
      onMouseEnter={()=>{
        this.props.setHover(this.props.items[10].headline);
      }}
      onMouseLeave={()=>{this.props.setHover('', '')}}
      >
      <DNA history={this.props.history}
      number={'11'}
      {...this.props.items[10]}
      showOnly={this.state.showOnly}
      />
      </div>

      <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
      to={'12'}
      onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('12');
        }
        this.props.setShowFilterMenu(false);
      }}
      className={this.props.isShowMenu? 'dnaHovered':'dna'}
      onMouseEnter={()=>{
        this.props.setHover(this.props.items[11].headline);
      }}
      onMouseLeave={()=>{this.props.setHover('', '')}}
      >
      <DNA history={this.props.history}
      number={'12'}
      {...this.props.items[11]}
      showOnly={this.state.showOnly}
      />
       </div>
      </div>

       <div style={{
       marginTop: '1.9vh',
       display: 'flex',
       flexDirection: 'row',
       height: '36.4vh'
       }}>
       <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
       to={'13'}
       onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('13');
        }
        this.props.setShowFilterMenu(false);
      }}
       className={this.props.isShowMenu? 'dnaHovered':'dna'}
       onMouseEnter={()=>{
         this.props.setHover(this.props.items[12].headline);
       }}
       onMouseLeave={()=>{this.props.setHover('', '')}}
       >
       <DNA history={this.props.history}
       bottomNumber={'13'}
       {...this.props.items[12]}
       showOnly={this.state.showOnly}
       />
       </div>

       <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
       to={'14'}
       onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('14');
        }
        this.props.setShowFilterMenu(false);
      }}
       className={this.props.isShowMenu? 'dnaHovered':'dna'}
       onMouseEnter={()=>{
         this.props.setHover(this.props.items[13].headline);
       }}
       onMouseLeave={()=>{this.props.setHover('', '')}}
       >
       <DNA history={this.props.history}
       bottomNumber={'14'}
       {...this.props.items[13]}
       showOnly={this.state.showOnly}
       />
       </div>

       <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
       to={'15'}
       onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('15');
        }
        this.props.setShowFilterMenu(false);
      }}
       className={this.props.isShowMenu? 'dnaHovered':'dna'}
       onMouseEnter={()=>{
         this.props.setHover(this.props.items[14].headline);
       }}
       onMouseLeave={()=>{this.props.setHover('', '')}}
       >
       <DNA history={this.props.history}
       bottomNumber={'15'}
       {...this.props.items[14]}
       showOnly={this.state.showOnly}
       />
       </div>

       <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
       to={'16'}
       onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('16');
        }
        this.props.setShowFilterMenu(false);
      }}
       className={this.props.isShowMenu? 'dnaHovered':'dna'}
       onMouseEnter={()=>{
         this.props.setHover(this.props.items[15].headline);
       }}
       onMouseLeave={()=>{this.props.setHover('', '')}}
       >
       <DNA history={this.props.history}
       bottomNumber={'16'}
       {...this.props.items[15]}
       showOnly={this.state.showOnly}
       />
       </div>

       <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
       to={'17'}
       onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('17');
        }
        this.props.setShowFilterMenu(false);
      }}
       className={this.props.isShowMenu? 'dnaHovered':'dna'}
       onMouseEnter={()=>{
         this.props.setHover(this.props.items[16].headline);
       }}
       onMouseLeave={()=>{this.props.setHover('', '')}}
       >
       <DNA history={this.props.history}
       bottomNumber={'17'}
       {...this.props.items[16]}
       showOnly={this.state.showOnly}
       />
       </div>

       <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
       to={'18'}
       onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('18');
        }
        this.props.setShowFilterMenu(false);
      }}
       className={this.props.isShowMenu? 'dnaHovered':'dna'}
       onMouseEnter={()=>{
         this.props.setHover(this.props.items[17].headline);
       }}
       onMouseLeave={()=>{this.props.setHover('', '')}}
       >
       <DNA history={this.props.history}
       bottomNumber={'18'}
       {...this.props.items[17]}
       showOnly={this.state.showOnly}
       />
       </div>

       <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
       to={'19'}
       onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('19');
        }
        this.props.setShowFilterMenu(false);
      }}
       className={this.props.isShowMenu? 'dnaHovered':'dna'}
       onMouseEnter={()=>{
         this.props.setHover(this.props.items[18].headline);
       }}
       onMouseLeave={()=>{this.props.setHover('', '')}}
       >
       <DNA history={this.props.history}
       bottomNumber={'19'}
       {...this.props.items[18]}
       showOnly={this.state.showOnly}
       />
       </div>

       <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
       to={'20'}
       onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('20');
        }
        this.props.setShowFilterMenu(false);
      }}
       className={this.props.isShowMenu? 'dnaHovered':'dna'}
       onMouseEnter={()=>{
         this.props.setHover(this.props.items[19].headline);
       }}
       onMouseLeave={()=>{this.props.setHover('', '')}}
       >
       <DNA history={this.props.history}
       bottomNumber={'20'}
       {...this.props.items[19]}
       showOnly={this.state.showOnly}
       />
       </div>

       <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
       to={'21'}
       onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('21');
        }
        this.props.setShowFilterMenu(false);
      }}
       className={this.props.isShowMenu? 'dnaHovered':'dna'}
       onMouseEnter={()=>{
         this.props.setHover(this.props.items[20].headline);
       }}
       onMouseLeave={()=>{this.props.setHover('', '')}}
       >
       <DNA history={this.props.history}
       bottomNumber={'21'}
       {...this.props.items[20]}
       showOnly={this.state.showOnly}
       />
       </div>

       <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}}
       to={'22'}
       onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('22');
        }
        this.props.setShowFilterMenu(false);
      }}
       className={this.props.isShowMenu? 'dnaHovered':'dna'}
       onMouseEnter={()=>{
         this.props.setHover(this.props.items[21].headline);
       }}
       onMouseLeave={()=>{this.props.setHover('', '')}}
       >
       <DNA history={this.props.history}
       bottomNumber={'22'}
       {...this.props.items[21]}
       showOnly={this.state.showOnly}
       />
       </div>

       <div style={{textDecoration: 'none'}}  onClick={e => {if (this.props.isShowMenu) {return e.preventDefault()}}
         }
       to={'23'}
       onClick={()=>{
        if(this.props.filterGroup!==0) {
          this.props.history.push('23');
        }
        this.props.setShowFilterMenu(false);
      }}
       className={this.props.isShowMenu? 'dnaHovered':'dna'}
       onMouseEnter={()=>{
         this.props.setHover(this.props.items[22].headline);
       }}
       onMouseLeave={()=>{this.props.setHover('', '')}}
       >
       <DNA history={this.props.history}
       bottomNumber={'23'}
       {...this.props.items[22]}
       showOnly={this.state.showOnly}
       />
        </div>

        <div>
        <Description
        onMouseExit={()=>{
          this.setState({showOnly: 'all'})
        }}
        onTradition={()=> {
          this.setState({showOnly: 0})
        }}
        onBeliefs={()=> {
          this.setState({showOnly: 1})
        }}
        onNarratives={()=> {
          this.setState({showOnly: 2})
        }}
        onArts={()=> {
          this.setState({showOnly: 3})
        }}
        onLanguage={()=> {
          this.setState({showOnly: 4})
        }}
        />
        </div>

        
      </div>
    </div>
  );
  }
}

const mapStateToProps = state => {
  return {
    displayMenu: state.displayMenu,
    isShowMenu: state.isMenuShown,
    filterGroup: state.filterGroup
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMenu: (id, func) => dispatch({type: Actions.SET_MENU, id: id, func: func}),
    setShowFilterMenu: (isShown) => dispatch({type: Actions.SET_SHOW_FILTER_MENU, isShown: isShown})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Landpage);
