import React, { Component} from 'react';
import './DNA.css';

const DNA_HEIGHT = 34.1;
const DNA_WIDTH = 6.6;

class DNAEmpty extends Component {
  constructor(props) {
    super(props);
    this.heightsArr = this.getAllHeightsArr();
    document.body.style.transition = 'background-color 1s';
  }
  state = {
    tradition: [],
    beliefs: [],
    narrative: [],
    arts: [],
    language: []
  }

  DNACell = (color, height, hIndex, elementIndex) => {
    let h = DNA_HEIGHT*height/100;//2 is the margin that take place from bottom.
    this.allHeights[hIndex]+=h;//here we want to count the height with the margin.
    let regular = {...regularCSS};
    regular.backgroundColor = 'rgba(0, 0, 0, 0)';
    regular.color = color;
    regular.height = h+'vh';
    regular.border = '1px solid rgba(0, 0, 0, 0.3)'

    return (
      <div key={elementIndex+'-'+hIndex}
      style={regular}
      onClick={()=>{
        this.props.setScale(elementIndex);
        document.body.style.backgroundColor = color;
      }}
      >

      </div>
    );
  }

  render() {
    let counterIndex = 0;
    this.allHeights = [0,0,0,0,0];
    this.allViews = [];
      let traditionView = this.props.tradition.map((tradition, index)=> {
        return this.DNACell('#ff4e4e', tradition.height,0,counterIndex++);
      });
      this.allViews = [...this.allViews, ...traditionView];


      let beliefsView = this.props.beliefs.map((beliefs, index)=> {
      return this.DNACell('#b47575', beliefs.height,1,counterIndex++);
      });
      this.allViews = [...this.allViews, ...beliefsView];


      let narrativeView = this.props.narratives.map((narrative, index)=> {
        return this.DNACell('#ff9c9a', narrative.height,2,counterIndex++);
      });
      this.allViews = [...this.allViews, ...narrativeView];


        let artView = this.props.arts.map((art, index)=> {
          let hide = !(this.props.showOnly===3);
          if (this.props.showOnly === 'all') {
            hide = false;
          }
          return this.DNACell('#14ad8d', art.height,3,counterIndex++);
        });
        this.allViews = [...this.allViews, ...artView];


        let languageView = this.props.languages.map((language, index)=> {
          let hide = !(this.props.showOnly===4);
          if (this.props.showOnly === 'all') {
            hide = false;
          }
          return this.DNACell('#ffcc57', language.height,4,counterIndex++);
        });

        this.allViews = [...this.allViews, ...languageView];
        let topNumber = (<div style={{fontFamily: 'Miriam Libre', fontWeight: 'bold', color:'#ece5d9'}}>
                          {this.props.number}
                        </div>);
        let botNumber = (<div style={{fontFamily: 'Miriam Libre', fontWeight: 'bold', color:'#ece5d9'}}>
                          {this.props.bottomNumber}
                        </div>);

                        //WITHOUT THIS THE SECOND DNA ROW WILL HAVE TROUBLE TO STAY IN PLACE
        let temp = (this.props.showOnly ==='all')? DNA_HEIGHT: this.allHeights[this.props.showOnly];
        let emptyStyle = {transition: 'all 0.5s ease', opacity: 1};
        if (this.allHeights[this.props.showOnly] === 0 ) {
          emptyStyle.opacity = 0;
        }
        let holder = this.handleScroll(this.props.holder, this.props.singleSize);
        let view = (
           <div style={{height: temp+'vh',
            transition: 'all 0.5s ease',
             width: DNA_WIDTH+'vw',//
             margin: '0px 4px 0px 4px',
             cursor: 'crosshair'
           }}>
              <div id={'selector'} style={{position: 'absolute',
                zPosition: 10,
                marginTop: holder.mt,
                width: 'calc('+DNA_WIDTH+'vw - 4px)',
                height: holder.h,
                borderStyle: 'solid',
                transition: 'all 0.5s ease',
              }}
                  >
              </div>

              <div style={emptyStyle}>
                  {traditionView}
                  {beliefsView}
                  {narrativeView}
                  {artView}
                  {languageView}
                </div>
              </div>
            );

    return(<div>
      {view}
    </div>);
  }
  getAllHeightsArr = () => {
    let arr = [];
    let keys = ['tradition','beliefs', 'narratives', 'arts', 'languages'];

    for (let k of keys) {
      if (this.props[k] && this.props[k].length > 0) {
        for (let v of this.props[k]) {
          arr.push(v.height*DNA_HEIGHT/100);
        }
      }
    }
    return arr;
  };
  handleScroll = (currentScroll, singleSize) => {
    let max = this.heightsArr.length*currentScroll;
    let currentIndex = Math.floor(currentScroll/singleSize);
    let currentHeight = this.heightsArr[currentIndex];
    let currentPrecentage = currentScroll/singleSize-Math.floor(currentScroll/singleSize);
    let nextHeight = this.heightsArr[currentIndex+1]? this.heightsArr[currentIndex+1]: currentHeight;
    let nextDelta = nextHeight - currentHeight;
    let mt = 0;
    let mtOffset = 0;

    if (this.allViews[currentIndex]) {//Set the body color
      document.body.style.backgroundColor = this.allViews[currentIndex].props.style.color;
    }
    this.heightsArr.forEach((h, index) => {//SET ALL PREVIUS MARGIN. (LIKE IF WE ARE AT 3/5 calc the first 2)
      if(index>=currentIndex) {
        return false;
      }
      mtOffset += 0;
      mt+=h;
    });
    if (currentIndex !== this.heightsArr.length-1) {
      mt += currentPrecentage*currentHeight;
    }
    let newH = nextDelta*currentPrecentage + currentHeight;
    return {h: 'calc('+newH+'vh - 2px)', mt: 'calc('+mt+'vh + '+0+'px)'}
  }
}


const regularCSS = {
      transition: 'all 0.5s ease',
      overflow: 'default',
      margin: '0px 0px -1px 0px',
      height: '100%',
      width: DNA_WIDTH+'vw',
    };


export default DNAEmpty;
