import React, { Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Pattern1 from '../../assets/filters/patterns/1.png';
import Pattern2 from '../../assets/filters/patterns/2.png';
import Pattern3 from '../../assets/filters/patterns/3.png';
import Pattern4 from '../../assets/filters/patterns/4.png';
import Pattern5 from '../../assets/filters/patterns/5.png';
import Pattern6 from '../../assets/filters/patterns/6.png';
import Pattern7 from '../../assets/filters/patterns/7.png';
import Pattern8 from '../../assets/filters/patterns/8.png';
import Pattern9 from '../../assets/filters/patterns/9.png';
import Pattern10 from '../../assets/filters/patterns/10.png';
import Pattern11 from '../../assets/filters/patterns/11.png';
import Pattern12 from '../../assets/filters/patterns/12.png';
import Pattern13 from '../../assets/filters/patterns/13.png';



import './DNA.css';



const colorGrades = ["#a355d3", "#eea3ff", "#e1d4e7"];

// const DNA_HEIGHT = 368;
const DNA_HEIGHT = 34.1;
const DNA_WIDTH = 128;

class DNA extends Component {


  state = {
    tradition: [],
    beliefs: [],
    narrative: [],
    arts: [],
    language: []
  }

  DNACell = (color, height, hIndex, elementIndex, isHidden) => {
    let h = DNA_HEIGHT*height/100;//2 is the margin that take place from bottom.
    this.allHeights[hIndex]+=h;//here we want to count the height with the margin.
    let regular = {...regularCSS};
    let hidden = {...hiddenCssDown};
    hidden.backgroundColor = color;
    hidden.top = height+'%  ';
    regular.backgroundColor = color;
    regular.height = 'calc('+h+'vh - 2px)';//height of component of dna. dna cell
    regular.zIndex = 10 - elementIndex;
    let css = isHidden? hidden:regular;
    return (
      <div hidden={isHidden} key={elementIndex+'-'+hIndex} index={hIndex}
      style={css}>

      </div>
    );
  }

  render() {

    this.allHeights = [0,0,0,0,0];
    this.allViews = [];
    this.countCells = 0;
      let traditionView = this.props.tradition.map((tradition, index)=> {
        let hide = !(this.props.showOnly===0);
        if (this.props.showOnly === 'all') {
          hide = false;
        }
        this.countCells++;
        return this.DNACell('#ff4e4e', tradition.height,0,index,hide,this.countCells);
      });
      this.allViews.push(traditionView);

      let beliefsView = this.props.beliefs.map((beliefs, index)=> {
        let hide = !(this.props.showOnly===1);
        if (this.props.showOnly === 'all') {
          hide = false;
        }
        this.countCells++;
        return this.DNACell('#b47575', beliefs.height,1,index,hide,this.countCells);
      });
      this.allViews.push(beliefsView);


        let narrativeView = this.props.narratives.map((narrative, index)=> {
          let hide = !(this.props.showOnly===2);
          if (this.props.showOnly === 'all') {
            hide = false;
          }
          this.countCells++;
          return this.DNACell('#ff9c9a', narrative.height,2,index,hide, this.countCells);
        });
        this.allViews.push(narrativeView);


        let artView = this.props.arts.map((art, index)=> {
          let hide = !(this.props.showOnly===3);
          if (this.props.showOnly === 'all') {
            hide = false;
          }
          this.countCells++;
          return this.DNACell('#14ad8d', art.height,3,index,hide, this.countCells);
        });
        this.countCells++;
        this.allViews.push(artView);


        let languageView = this.props.languages.map((language, index)=> {
          let hide = !(this.props.showOnly===4);
          if (this.props.showOnly === 'all') {
            hide = false;
          }
          this.countCells++;
          return this.DNACell('#ffcc57', language.height,4,index,hide, this.countCells);
        });
        this.allViews.push(languageView);
        let topNumber = (<div style={{fontFamily: 'Miriam Libre', fontWeight: 'bold', color:'#ece5d9', marginBottom: '0.5vh'}}>
                          {this.props.number}
                        </div>);
        let botNumber = (<div style={{fontFamily: 'Miriam Libre', fontWeight: 'bold', color:'#ece5d9', marginTop: '0.65vh', textDecoration: 'none'}}>
                          {this.props.bottomNumber}
                        </div>);

                        //WITHOUT THIS THE SECOND DNA ROW WILL HAVE TROUBLE TO STAY IN PLACE
        let temp = (this.props.showOnly ==='all')? DNA_HEIGHT: this.allHeights[this.props.showOnly];
        let emptyStyle = {transition: 'all 0.5s ease', opacity: 1};
        if (this.allHeights[this.props.showOnly] === 0 ) {
          emptyStyle.opacity = 0;
        }
        let filterHeights = (this.props.filterGroup > 0 && this.props.filterGroup < 10)? this.props.regularFilters[this.props.filterGroup-1]: [0,0,0];
        let countBorders = filterHeights.filter((fh, index)=> fh>0).length;

        let displayNumber = this.shouldDisplayNumber(traditionView, beliefsView, narrativeView, artView, languageView);
        let view = (
           <div label="view-test" style={{
            //  height: temp+'vh',//maybe return?
            transition: 'all 0.5s ease',
             width: '6.7vw',
             margin: '0px 0.5vw'
           }}>
           <div style={{transition: 'all 0.5s ease', opacity: displayNumber? 1: 0, fontSize: '0.78125vw'}}>
            {this.props.number? topNumber: null}
           </div>

              <div style={emptyStyle}>
                  <div style={{
                   width: this.props.filterGroup===0?'100%':'18px',
                    maxWidth: '6.7vw',
                     position: 'absolute',
                      height: '34.1vh',
                    }}>
                  {this.regularFiltersView()}
                  {this.specialFiltersView()}
                  </div>
                  {traditionView}
                  {beliefsView}
                  {narrativeView}
                  {artView}
                  {languageView}
                  <div style={{transition: 'all 0.5s ease', opacity: displayNumber? 1: 0, fontSize: '0.78125vw'}}>
                    {this.props.bottomNumber? botNumber: null}
                  </div>
                </div>
              </div>);


          let myH = 0;
          if (this.props.showOnly !== 'all') {
            for (let i=0;i<this.props.showOnly;i++) {
              myH+=this.allHeights[i];
            }
          }
    let hiddenView = (
       <div style={{height: 'calc('+myH+'vh - 2px)',
       display: 'flex',
       justifyContent: 'flex-end',
       flexDirection: 'column',
       transition: 'all 0.5s ease'
       }}>
        </div>
        );

    return(<div>
      {hiddenView}
      {view}
    </div>);
  }
  regularFiltersView = () => {
    let filterHeights = (this.props.filterGroup > 0 && this.props.filterGroup < 10)? this.props.regularFilters[this.props.filterGroup-1]: [0,0,0];
    let minus = filterHeights.filter(f=>f!==0).length;
    let takeOffEach = Math.abs((this.countCells-1)*2-minus*2)/minus;
    let tmp = [2,2,1];
    return (<>
                      {filterHeights[0] > 0? <div style={{
                        width: this.props.selectedFilter[0].isActive?'100%':'0%',
                        height: 'calc('+filterHeights[0]+'% - '+2+'px)',
                       backgroundColor: this.props.selectedFilter[0].isActive?colorGrades[0]:'rgba(0,0,0,0)',
                         border: this.props.selectedFilter[0].isActive?'solid 2px #190707':'solid 2px rgba(0,0,0,0)',
                         transition: 'all 0.5s ease, height 1ms 0.5s',
                         marginTop: '-2px',
                         marginLeft: '-2px',
                         borderLeft: '0px'
                       }}/>: null}
                        {filterHeights[1] > 0? <div style={{
                          width: this.props.selectedFilter[1].isActive?'100%':'0%',
                          height: 'calc('+filterHeights[1]+'% - '+2+'px)',
                         backgroundColor: this.props.selectedFilter[1].isActive?colorGrades[1]:'rgba(0,0,0,0)',
                         border: this.props.selectedFilter[1].isActive?'solid 2px #190707':'solid 2px rgba(0,0,0,0)',
                         transition: 'all 0.5s ease, height 1ms 0.5s',
                           marginTop: '-2px',
                           marginLeft: '-2px',
                           borderLeft: '0px'

                         }}/>: null}
                        {filterHeights[2] > 0? <div style={{
                          width: this.props.selectedFilter[2].isActive?'100%':'0%',
                           height: 'calc('+filterHeights[2]+'% - '+2+'px)',
                           backgroundColor: this.props.selectedFilter[2].isActive?colorGrades[2]:'rgba(0,0,0,0)',
                            border: this.props.selectedFilter[2].isActive?'solid 2px #190707':'solid 2px rgba(0,0,0,0)',
                            transition: 'all 0.5s ease, height 1ms 0.5s',
                            marginTop: '-2px',
                            marginLeft: '-2px',
                            borderLeft: '0px'

                          }}/>: null}
                        </>);
  };
  specialFilterView = (filterHeights, index, show) => {
    let tmpImg = this.getImg(index);
    tmpImg.props.style.height = show?100+'%':'0px';
    tmpImg.props.style.objectFit = 'cover';

    return (
      <>
      <div style={{
      width:  '100%',
      height: DNA_HEIGHT*filterHeights[index]/100+'vh',
       border: show>0?'solid 2px 0px #190707':'solid 0px #190707',
       transition: show? 'opacity 0.5s ease':'opacity 0.5s ease, height 1ms ease 0.5s, border 1ms ease 0.5s',
       opacity: show&&filterHeights[index]>0? 1:0,
       backgroundColor: 'rgba(0,0,0,1)'
     }}>
     {tmpImg}
   </div>
   </>)
  };

  shouldDisplayNumber = (traditionView, beliefsView, narrativeView, artView, languageView) => {
    for (let view of traditionView) {
      if (!view.props.hidden) {
        return true;
      }
    }
    for (let view of beliefsView) {
      if (!view.props.hidden) {
        return true;
      }
    }
    for (let view of narrativeView) {
      if (!view.props.hidden) {
        return true;
      }
    }
    for (let view of artView) {
      if (!view.props.hidden) {
        return true;
      }
    }
    for (let view of languageView) {
      if (!view.props.hidden) {
        return true;
      }
    }
  }


  getImg = (index) => {
   switch (index) {
     case 1:
       return  <img src={Pattern1} alt="filter1" style={{width: 100+'%'}}/>
       break;
     case 2:
       return  <img src={Pattern2} alt="filter1" style={{width: 100+'%'}}/>
       break;
     case 0:
       return  <img src={Pattern3} alt="filter1" style={{width: 100+'%'}}/>
       break;
     case 3:
       return  <img src={Pattern4} alt="filter1" style={{width: 100+'%'}}/>
       break;
     case 4:
       return  <img src={Pattern5} alt="filter1" style={{width: 100+'%'}}/>
       break;
     case 5:
       return  <img src={Pattern6} alt="filter1" style={{width: 100+'%'}}/>
       break;
     case 6:
       return  <img src={Pattern7} alt="filter1" style={{width: 100+'%'}}/>
       break;
     case 7:
       return  <img src={Pattern8} alt="filter1" style={{width: 100+'%'}}/>
       break;
     case 8:
       return  <img src={Pattern9} alt="filter1" style={{width: 100+'%'}}/>
       break;
     case 9:
       return  <img src={Pattern10} alt="filter1" style={{width: 100+'%'}}/>
       break;
     case 10:
       return  <img src={Pattern11} alt="filter1" style={{width: 100+'%'}}/>
       break;
     case 11:
       return  <img src={Pattern12} alt="filter1" style={{width: 100+'%'}}/>
       break;
     case 12:
       return  <img src={Pattern13} alt="filter1" style={{width: 100+'%'}}/>
       break;
     default:
   }
  }

  specialFiltersView = () => {
    if (this.props.filterGroup !== 0) {
      return null;
    }
    let filterHeights = this.props.specialFilters;
    let mappedFilters = filterHeights.map((h, index) => {
      return filterHeights[index]>0?<div key={index}>{this.specialFilterView(filterHeights, index, this.props.selectedFilter[index].isActive)}</div>:null;
    });
    let finalFiltered = mappedFilters.filter((item, index) => {
      return item != null;
    });

    let finalViewWithClick = finalFiltered.map((item, index) => {
      return <div onClick={()=>{
        this.props.history.push((this.props.dnaIndex+1)+'/'+(index+1));
      }}
       key={index}>{item}</div>
    })
    return (
            <>
              {finalViewWithClick}
            </>
            );
  }


}


const hiddenCssDown = {
    transition: 'all 0.5s ease',
    overflow: 'hidden',
    display: 'flex',
    margin: '0px 0px 0px 0px',
    height: '0px',
    width: 6.7+'vw',
};
const regularCSS = {
      transition: 'all 0.5s ease',
      overflow: 'default',
      margin: '0px 0px 2px 0px',
      height: '100%',
      width: 6.7+'vw',
    };


const mapStateToProps = state => {
  return {
    displayMenu: state.displayMenu,
    isShowMenu: state.isMenuShown,
    filterGroup: state.filterGroup,
    selectedFilter: state.selectedFilter
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // setMenu: (id) => dispatch({type: Actions.SET_MENU_IMG, id: id}),
    // setShowFilterMenu: (isShown) => dispatch({type: Actions.SET_SHOW_FILTER_MENU, isShown: isShown})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DNA);
