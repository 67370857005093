import React, { Component} from 'react';
import { connect } from 'react-redux';

import * as Actions from "../../store/actions";
import './Filter.css';

class FilterTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transitionTime:0.5,
      titleHeight: "0px",
      hHeight: '2.55vh',
      isActive: false,
      colorTransitionTime: 0.5,
      delayTime: this.props.delay
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.show !== this.props.show) {
      if (this.props.show) {
        setTimeout(()=>{
          this.setState({titleHeight: "2.55vh", hHeight: '0px'})
        },5);
        // // This will set selective animnation
        setTimeout(()=> {
          this.setState({delayTime: 0});
        }, 2000);
        // setTimeout(()=> {
        //   this.setState({isActive: false});
        // }, 2300 + this.props.delay*260);
      } else {//Doesn't show/ reset all ta initiate
        setTimeout(()=>{
          this.setState({titleHeight: "0px", hHeight: '1.7vh', delayTime: this.props.delay})
        },5)
      }
    }
  }


  render() {
    return (
      <div style={{
           height: '2.55vh',
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'space-between'
          }}>
          <div style={{
                        height: this.state.hHeight,
                        overflowY: 'hidden',
                        transitionDelay: this.props.show? this.state.delayTime+'s' : '0s',
                        transitionDuration: this.state.transitionTime+'s'}}>
          </div>

          <div className={['filter-title ', (this.props.isActive || this.state.isActive)? 'active':null]} style={{
                display: 'flex',
                flexDirection: 'row',
                cursor: 'crosshair',
                whiteSpace: 'nowrap',
                height: this.state.titleHeight,
                overflowY: 'hidden',
                transitionDelay: this.props.show? this.state.delayTime+'s' : '0s',
                transitionDuration: this.state.transitionTime+'s',
                opacity: (this.props.isActive || this.state.isActive)? 1 : 0.7,
              }}
              onClick={()=>{
                this.props.setFilterIndex(this.props.index)
              }}
              >       <div style={{display: 'flex',
                              flexDirection: 'column-reverse', width:'2px'}}>
                        
                          <div style={{height: '88%',borderRight: 'solid 2px #ece5d9', borderBottom: 'solid 2px #ece5d9'}}/>
                        
                          <div style={{height: '12%'}}/>
                        
                      </div>
                      <div className={'filter-title'} style={{
                        opacity: (this.props.isActive || this.state.isActive)? 1 : 0.4,
                        transitionDuration: this.state.colorTransitionTime+'s',
                        fontFamily: "Miriam Libre",
                        fontWeight: 'bold',
                        fontSize: '0.835vw',
                        marginTop: '-0.3vh',
                        paddingRight: '14px',
                        paddingLeft: '4px',}}>
                        {this.props.heb}
                      </div>
                      <div className={['filter-title','rus-font'].join(" ")} style={{
                        opacity: (this.props.isActive || this.state.isActive)? 1 : 0.4,
                        transitionDuration: this.state.colorTransitionTime+'s',
                        fontWeight: 500,
                        padding: '0px',
                        fontSize: '0.835vw',
                        marginTop: '-0.3vh',
                        paddingLeft: '12px'}}>
                          {this.props.rus}
                      </div>
          </div>
      </div>

  )
  }
}


const mapStateToProps = state => {
  return {
    displayMenu: state.displayMenu
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFilterIndex: (index) => dispatch({type: Actions.SET_FILTER_GROUP, index: index})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterTitle);
