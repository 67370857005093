import React, { Component} from 'react';
import { connect } from 'react-redux';

const DNA_HEIGHT = 368;
const DNA_WIDTH = 128;

class Description extends Component {
  render() {
      return(
        <div style={{
          height: '34.1vh',
         transition: 'all 0.5s ease',
          width: '6.7vw',
          margin: '0px 0.5vw', 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end'
        }}>
        {this.textElement(this.props.onTradition, this.props.onMouseExit, 'מסורת','Традиции','#ff4e4e')}
        {this.textElement(this.props.onBeliefs, this.props.onMouseExit, 'אמונות','Убеждения','#b47575')}
        {this.textElement(this.props.onNarratives, this.props.onMouseExit, 'נרטיבים','Повествования','#ff9ca9')}
        {this.textElement(this.props.onArts, this.props.onMouseExit, 'אומנויות','Искусства','#14ad8d')}
        {this.textElement(this.props.onLanguage, this.props.onMouseExit, 'שפה','Язык','#ffcc57')}
      </div>
    );
    }
    textElement = (hoverIn, hoverOut, heb, rus,color) => {
      return (
        <div
        onMouseEnter={this.props.isShowMenu? ()=>{}: hoverIn}
        onMouseLeave={this.props.isShowMenu? ()=>{}: hoverOut}

        style={{
        fontFamily: 'Miriam Libre',
        color: color,
        textAlign: 'right',
        fontWeight: 'bold',
        lineHeight: 1.06,
        cursor: 'default',
        marginTop: '1.7vh',
        opacity: this.props.isShowMenu? 0.2:1,
        fontSize: '0.835vw'}}>
         {heb}
         <div className={'rus-font'} style={{        
           fontWeight: 500}}>
         {rus}
         </div>
      </div>)
    }
}




const mapStateToProps = state => {
  return {
    displayMenu: state.displayMenu,
    isShowMenu: state.isMenuShown
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // setMenu: (id) => dispatch({type: Actions.SET_MENU_IMG, id: id}),
    // setShowFilterMenu: (isShown) => dispatch({type: Actions.SET_SHOW_FILTER_MENU, isShown: isShown})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Description);
