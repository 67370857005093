import React, { Component} from 'react';
import { easeInOutQuad } from 'js-easing-functions';
import { connect } from 'react-redux';
import * as Actions from "../../store/actions";
import { browserHistory } from 'react-router';


import DNAEmpty from '../DNA/DNAEmpty';
import VideoPlayer from '../VideoPlayer';
import './Display.css';
const TEXT_TOP_MARGIN = '20.7vh';
const items = [
{       //1
      tradition:[],
      beliefs:[],
      narratives:[],
      arts:[{height: 25},{height: 25},{height: 25},{height: 25}],
      languages:[]
    },
    {//2
      tradition:[],
      beliefs:[],
      narratives:[{height: 60}],
      arts:[{height: 20}],
      languages:[{height: 20}]
    },
    {//3
      tradition:[{height: 50}],
      beliefs:[{height: 15}],
      narratives:[],
      arts:[{height: 35}],
      languages:[]
    },
    {//4
      tradition:[],
      beliefs:[],
      narratives:[],
      arts:[{height: 50}, {height: 50}],
      languages:[]
    },
    {//5
      tradition:[{height: 50}],
      beliefs:[],
      narratives:[],
      arts:[{height: 50}],
      languages:[]
    },
    {//6
      tradition:[{height: 55}],
      beliefs:[{height: 45}],
      narratives:[],
      arts:[],
      languages:[],
    },
    {//7
      tradition:[{height: 30}],
      beliefs:[],
      narratives:[],
      arts:[{height: 70}],
      languages:[],
    },
    {//8
      tradition:[{height: 35}],
      beliefs:[],
      narratives:[],
      arts:[{height: 65}],
      languages:[],
    },
    {//9
      tradition:[{height: 10},{height: 10},{height: 20},{height: 40}],
      beliefs:[],
      narratives:[],
      arts:[],
      languages:[{height: 20}],
    },
    {//10
      tradition:[],
      beliefs:[],
      narratives:[{height: 60}],
      arts:[],
      languages:[{height: 40}],
    },
    {//11
      tradition:[{height: 33.3},{height: 33.3}],
      beliefs:[],
      narratives:[],
      arts:[{height: 33.3}],
      languages:[],
    },
    {//12
      tradition:[],
      beliefs:[{height: 20},{height: 20},{height: 20},{height: 20},{height: 20}],
      narratives:[],
      arts:[],
      languages:[],
    },
    {//13
      tradition:[{height: 50},{height: 50}],
      beliefs:[],
      narratives:[],
      arts:[],
      languages:[],
    },
    {//14
      tradition:[{height: 50}],
      beliefs:[],
      narratives:[],
      arts:[{height: 50}],
      languages:[],
    },
    {//15
      tradition:[{height: 33.3},{height: 33.3},{height: 33.3}],
      beliefs:[],
      narratives:[],
      arts:[],
      languages:[],
    },
    {//16
      tradition:[{height: 15}],
      beliefs:[{height: 85}],
      narratives:[],
      arts:[],
      languages:[],
    },
    {//17
      tradition:[{height: 50},{height: 50}],
      beliefs:[],
      narratives:[],
      arts:[],
      languages:[],
    },
    {//18
      tradition:[{height: 50},{height: 50}],
      beliefs:[],
      narratives:[],
      arts:[],
      languages:[],
    },
    {//19
      tradition:[],
      beliefs:[],
      narratives:[],
      arts:[{height: 50},{height: 50}],
      languages:[],
    },
    {//20
      tradition:[{height: 13}],
      beliefs:[],
      narratives:[],
      arts:[{height: 74}],
      languages:[{height: 13}],
    },
    {//21
      tradition:[{height: 38}],
      beliefs:[],
      narratives:[{height: 32}],
      arts:[{height: 10}],
      languages:[{height: 20}],
    },
    {//22
      tradition:[],
      beliefs:[],
      narratives:[],
      arts:[{height: 20},{height: 20},{height: 20},{height: 20},{height: 20}],
      languages:[]
    },
    {//23
      tradition:[],
      beliefs:[],
      narratives:[],
      arts:[],
      languages:[{height: 33.3},{height: 33.3},{height: 33.3}],
    }
];


const contentData = {
  1: [
    {
      title: "אוסף המאובנים של אמא",
      text: "מלבד היותה מעצבת גרפית, אמא היא גם מורת דרך. התשוקה הגדולה שלה היא לטייל, לראות וללמד על הנופים וההסטוריה של ארץ ישראל. בזמן שהיא חורשת את שבילי הארץ, מצפון ועד דרום, ממזרח למערב, היא אוספת מאובנים שהיא מוצאת. באוסף שלה יש כבר מאות של מאובנים ואבנים, וכל אחד מהם מיוחד ויפה בפני עצמו.",
      mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F1%2F1_main.webp?alt=media&token=654c5b97-5377-4c8b-ae11-ff9b98812b2c",
      leftImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F1%2F1_left.webp?alt=media&token=1fba8063-3275-4d5f-ad52-209b5697d8b7",
      bottomTitle: {h: "חפצים", r: "Объекты"}
    },
    {
        title: "אוסף הצבים שלי",
        text: "הצב הראשון שקיבלתי היה צב סוורוסקי שקיבלתי מאחותי הגדולה. אני זוכרת איך בתור ילדה קטנה התהפנטתי מהנצנוץ והיופי של הסוורוסקי. לאחר מכן, החלטתי שאני מתחילה לאסוף צבים. לאט לאט, אספתי צבים מחומרים שונים, בצבעים שונים, בגדלים שונים וממקומות שונים. גם המשפחה נרתמה למשימה, ולאט לאט, גדל לו אוסף הצבים שלי. בשלב כלשהו, גיליתי שבמקומות מסויימים בעולם מאמינים שצב מסמל חיים ארוכים.",
        mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F1%2F2_main.webp?alt=media&token=a6ee4ebf-aed3-43da-9324-ecca4ee97128",
        bottomTitle: {h: "חפצים", r: "Объекты"}
      },
    {
        title: "אוסף הפרות של נינה",
        text: ["נינה היא אחותי הגדולה. מצד אחד אנחנו מאוד דומות, ומצד שני אנחנו מאוד שונות. מה שבטוח, לא יכולתי לבקש אחות יותר טובה, ואני יודעת שהיא שם בשבילי, לא משנה מה. היום היא שליחה בדטרויט מטעם הסוכנות היהודית, וגרה שם עם בעלה עמר, ושלושת ילדיהם - אלה, נעמה ואשל. ",
        <br key={1}/>,<br key={2}/>,
        "כשנינה למדה בבית ספר יסודי, היא שמה לב שפרה היא חיה שאף אחד לא אוהב. מאותו רגע, החליטה שהיא אוהבת פרות, ואוסף הפרות לא איחר להגיע. החדר שלה מלא בבובות של פרות מחומרים שונים וממדינות שונות."
      ],
        mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F1%2F3_main.webp?alt=media&token=a5fdecc1-6277-466a-b8e2-6dcc02281f75",
        subImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F1%2F3_sub.webp?alt=media&token=248ce48f-2912-40ff-be3a-9551a706fc6e",
        bottomTitle: {h: "חפצים", r: "Объекты"}
      },
    {
        title: "אוסף הכובעים של אבא",
        text: "אחד מהתחביבים של אבא הוא סאונה. בעקבות זאת, התחיל לאסוף כובעי סאונה שונים. בסופו של דבר, הבין שהוא מאוד אוהב את המגוון של הכובעים, ולאט לאט, החל לאסוף גם עוד סוגים של כובעים, מכל העולם. בכל פעם שחבר או קרוב משפחה נמצא בחו״ל, הוא מבקש שיביאו לו כובע מהמסורת של אותה מדינה.",
        mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F1%2F4_main.webp?alt=media&token=dca9cb08-cdcb-457c-a8db-7d0d7e41f9c6",
        bottomTitle: {h: "חפצים", r: "Объекты"}
      },
    ],
    2: [
      { 
        video: "https://vimeo.com/346763283/6b3c224e15",
        title: "סיפור העלייה של אמא ואבא",
        text: ["המשפחות של הוריי החליטו לעלות לארץ כי הבינו שאין עתיד ליהודים באותה תקופה בלטביה ורוסיה. האנטישמיות הייתה גדולה מאוד. הוריי הכירו אחד את השנייה דרך חברים משותפים לאחר שעלו לארץ בשנות השבעים.",
      <br/>,<br/>,
      "אמא עלתה ארצה עם שני הורייה ושתי אחיותייה במרץ 1974, בחג הפורים. ההתרגשות הייתה מאוד גדולה, ואחד הזכרונות של אמא מימיה הראשונים בארץ, הוא הפריחה האדומה של עצי הצאלון.",
      <br/>,<br/>,
      "משפחתו של אבא רצתה לעלות ארצה כבר לאחר מלחמת העולם השנייה דרך פולין, אך באותה תקופה החלו לאסור יהודים, והדבר התעכב. אפילו אבא היה עצור, ונאבק על זכותו לעלות לארץ במשך שנתיים. בשנת 1977, היה הראשון ממשפחתו שעלה ארצה."
      ],
        mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F2%2F1_main.webp?alt=media&token=b5140b14-307b-43af-aa97-4a1ad752e289",
        leftImg: "",
        bottomTitle: {h: "סיפורי עם", r: "Народные сказки"}
      },
      {
        title: "אשרת העלייה של אמא",
        text: "אמא תיעדה ושמרה חלק מהמסמכים שליוו את תהליך העלייה שלה ושל משפחתה לארץ. בין השאר, אפשר למצוא את אשרות העלייה שלהם, כרטיסי הטיסה של חברת אל על לארץ בשנות השבעים, ותמונות ישנות מהימים האחרונים של המשפחה בריגה.",
        mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F2%2F2_main.webp?alt=media&token=cc05a51d-f035-444c-b649-3b8ff4e75652",
        leftImg: "",
        bottomTitle: {h: "חפצים", r: "Объекты"}
      },
      {
        title: "אבא מדליק משואה",
        text: "בשנת 1984, אבא נבחר להיות אחד משניים עשר מדליקי המשואות ביום העצמאות הל״ו למדינת ישראל. הטקס נערך בהר הרצל בירושלים, כמדי שנה. הוא נבחר בגלל עבודתו במרכז קליטה לעולים חדשים מרוסיה וסוריה. הוא עבד בתור רכז קליטה חברתית במתנ״ס תל כביר בתל אביב.",
        mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F2%2F3_main.webp?alt=media&token=a3dc98fa-31bf-4d4d-9770-8dbab3c52db1",
        leftImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F2%2F3_left.webp?alt=media&token=3c59e04d-d9d8-4dbc-b5f6-a74167de536d",
        bottomTitle: {h: "דו לשוני", r: "Двуязычный"}
      },
    ],
    3: [
      {
        title: "מקומות קבועים",
        text: "כשאנחנו מתיישבים לארוחה משפחתית מסביב לשולחן האוכל במטבח, לכל אחת ואחת יש את מקום הישיבה הקבוע שלו. נינה תמיד יושבת לצד אמא ומול אבא, ואני תמיד יושבת לצד אבא ומול אמא. הסיפור מתחיל להיות מעניין, ברגע שיש אורחים. מי יפנה את המקום שלו ויעז לשבת במקום אחר אם צריך?",
        mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F3%2F1_main.webp?alt=media&token=f8b52ea5-7042-42eb-8b62-afea8bfe3d3e",
        leftImg: "",
        bottomTitle: {h: "מנהגים", r: "Традиции"},
      },
      {
        title: "סכין ומזלג",
        text: "קורה לפעמים שבזמן הארוחה, הסכין או המזלג נופלים לרצפה. ברגע זה, על פי האמונה המשפחתית, צריך לצפות לאיש או אישה שיגיעו לבית שלנו. אם הסכין נפלה, יגיע גבר, ואם המזלג נפל, תגיע אישה. עד היום, אני יכולה להגיד שהאמונה הטפלה הזו התגשמה בכל פעם.",
        mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F3%2F2_main.webp?alt=media&token=332b2187-2385-4262-a0a9-ac00aff01c4f",
        leftImg: "",
        bottomTitle: {h: "אמונות טפלות", r: "Суеверия"}
      },
      {
        title: "תחתית לצלחת",
        text: "כשמתיישבים לאכול, על פי החוקים של אמא, אי אפשר לאכול ללא תחתית לצלחת. לפעמים, תלוי במנה, צריך בנוסף לתחתית עוד צלחת. אמא טוענת שזה בשביל שלא יהיה לכלוך. אני חושבת שהסיבה האמיתית היא שהיא רוצה שהשולחן יראה טוב.",
        mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F3%2F3_main.webp?alt=media&token=a67b7d09-134c-43be-a381-03518d2dbc9f",
        leftImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F3%2F3_left.webp?alt=media&token=a94cc163-be9e-4e65-9e7e-53cd720409e3",
        bottomTitle: {h: "חפצים", r: "Объекты"}
      },
      ],
    4: [
        {
            title: "קוקלקה",
            text: "ברוסית, קוקלקה היא בובה. וזה השם שנתתי לה. לקוקלקה הייתה אחות תאומה, שאבדה לי איפשהו במהלך השנים. ישנתי איתן כל לילה. הן שמרו עליי מכל רע. הייתי ישנה איתן מחובקת, ומדי פעם הייתי מניחה אותן בכפות רגליי ומשחקת עם השיער שלהן. קוקלקה נמצאת בחדר שלי שבבית הוריי בכוכב יאיר, ועד היום אנחנו ישנות יחד.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F4%2F1_main.webp?alt=media&token=bcd4eb0b-6805-4701-89da-cfcb3431c340",
            leftImg: "",
            bottomTitle: {h: "חפצים", r: "Объекты"}
        },
        {
            title: "מישקה",
            text: "את מישקה קיבלתי ממוסייה, אחת המטפלות שהייתה לאחותי ולי בילדותנו. מישקה הוא ליצן, אך את התלבושת שלו, אני לא יודעת איפה שמתי. עם מישקה הייתי משחקת בבית וגם בגן השעשועים. אך הוא לעולם לא ישן איתי, כי את התפקיד הזה קיבלה קוקלקה, הבובה השנייה שלי.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F4%2F2_main.webp?alt=media&token=11537306-4811-495b-aa26-8dec1688ba97",
            leftImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F4%2F2_left.webp?alt=media&token=66f78296-9fed-4a0b-bb24-2e4a991b71a6",
            bottomTitle: {h: "חפצים", r: "Объекты"}
        },
    ],
    5: [
        {
            title: "הקלטות שירים באירועים משפחתיים",
            text: ["אירועים משפחתיים יש לנו בשפע. ימי הולדת, לידות, ועוד אירועים משמחים. בכל אירוע כזה, אנו מתכנסים באולפן ההקלטות של מיקי בכפר סבא, ומכינים ״הפתעה״ לבעל או בעלת השמחה.",
                <br/>,<br/>,
            "לפעמים כותבים מחדש מילים ושרים לפי שיר מפורסם, לפעמים שרים את השיר כפי שהוא במקור, ולפעמים אפילו שרים באיטלקית. את חלק מהשירים שרים כולם, וחלק מהשירים שרות רק הבנות דודות (נינה, אני, דנה ונטע), כי אנחנו לא מזייפות כמו שאר בני המשפחה."],
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F5%2F1_main.webp?alt=media&token=908e2f94-7296-49a2-8c46-61ef2de58bee",
            leftImg: "",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },
        {
            title: "אבא",
            text: ["אבא שלי, אלכס, נולד בלנינגרד, רוסיה בספטמבר 1946, לג׳ניה ומרדכי קלויצקי. הוא היה הקטן מבין 4 אחים ואחיות: זויה, מקס ואירה. אבא ומשפחתו לא קיבלו חינוך יהודי, כי הדבר היה אסור, אך תמיד השתדלו לחגוג את חגי ישראל, אפילו באופן מחתרתי.",
                <br/>,<br/>,
            "היום אבא מנהל קמפיינים פרסומיים של חברות שונות, מפלגות, מוסדות ממשלתיים ועוד. אני מאוד דומה לאבא בהמון דברים. קיבלתי ממנו את האהבה לחיים הטובים, את הרצון לחפש טוב בכל דבר, את העקשנות והנטייה לעבודה קשה מההתחלה ועד הסוף, וכנראה שגם את ההומור הציני. מה שבטוח, השירה לא הגיעה מהצד של אבא, כי הוא בהחלט זייפן גדול."],
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F5%2F2_main.webp?alt=media&token=f8134f26-e78d-4447-a594-f35c462b0d5b",
            leftImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F5%2F2_left.webp?alt=media&token=f43235b4-57e1-41ea-ac38-4ee451db4b6d",
            bottomTitle: {h: "מוזיקה", r: "Музыка"}
        },
    ],
    6: [
        {
            title: "מקומות קבועים",
            text: "ממש לפני שאחד או אחת מבני המשפחה יוצאים מהבית לכיוון שדה התעופה, אנחנו מתכנסים כולם יחד, מסביב לשולחן. הטקס מתקיים בשביל לוודא שמי שנוסע לא שכח את הדרכון, ובעיקר, בשביל לאחל נסיעה טובה ובטוחה. הטקס מתקיים בכל שעות היום ובכל שעות הלילה.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F6%2F1_main.webp?alt=media&token=c5de3557-41c9-4471-85ad-5406c2a075c6",
            leftImg: "",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },
        {
            title: "טיסה לחו״ל",
            text: "לפני כל טיסה של מישהו או מישהי מבני המשפחה, אנו מתכנסים מסביב לשולחן האוכל במטבח, מאחלים טיסה נעימה, בודקים שהדרכון נמצא, נותנים חיבוק וממשיכים הלאה. האמונה היא, שצריך לעשות זאת בשביל מזל טוב ונסיעה בטוחה. דרך אגב, גם אם הנסיעה היא באמצע הלילה, הטקס עדיין מתבצע, לא משנה מה היא השעה.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F6%2F2_main.webp?alt=media&token=26d19f88-2392-4da1-8454-a50f120cc9de",
            leftImg: "",
            bottomTitle: {h: "אמונות טפלות", r: "Суеверия"}
        },
    ],
    7: [
        {
            title: "חגיגת יום הולדת",
            text: "חגיגות יום ההולדת במשפחה הן אירוע גדול. מלבד חגיגה מצומצמת עם ההורים ונינה, בדרך כלל, מארגנים מפגש גם עם המשפחה המורחבת והחברים. לפעמים עורכים פיקניק, ולפעמים מזמינים לארוחה בבית של הוריי בכוכב יאיר. לפעמים יום ההולדת שלי יוצא במקביל ליום העצמאות, ואז החגיגה במשפחה היא כפליים.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F7%2F1_main.webp?alt=media&token=a188fa35-aab7-4da9-a033-02809318b644",
            leftImg: "",
            subImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F7%2F1_sub.webp?alt=media&token=8c3132f7-2391-4dfb-b4e6-8b5819648ffa",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },
        {
            title: "קישוטים בבוקר יום ההולדת",
            text: "בכל יום הולדת, אמא ואבא היו בוחרים חדר בבית, ומכינים אותו לבוקר היום הולדת של נינה ושלי. כאשר היינו קמות בבוקר, חיפשנו את החדר עם הבלונים, הקישוטים, הברכות והמתנות. כאשר גדלנו קצת, גם אנחנו הצטרפנו למסורת, והכנו חדר יום הולדת גם לאמא ואבא.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F7%2F2_main.webp?alt=media&token=b7b8d786-8b78-4d36-9207-60317d7810a8",
            leftImg: "",
            bottomTitle: {h: "יצירה", r: "Творчество"}
        },
    ],
    8: [
        {
            title: "הבר של אבא",
            text: ["בכל יום עצמאות, אנחנו מזמינים משפחה וחברים לעל האש בבית הוריי בכוכב יאיר. חלק מהתפריט, ומה שגורם לאבא להתרגש מאוד, הוא האלכוהול. אבא אוהב להגיש את האלכוהול המשובח ביותר ולהכין קוקטיילים לאורחים.",
                <br/>,<br/>,
                "האמת היא, שאמא לא כל כך אוהבת את הבר של אבא, כי חוץ מאירועים כמו יום העצמאות, הוא עומד בסלון ללא שימוש, והיא טוענת שהוא סתם תופס מקום. אולי יום אחד היא תצליח להפטר ממנו."],
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F8%2F1_main.webp?alt=media&token=405df116-59f2-4fce-b85d-89c2a57d63a2",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "אוכל", r: "Еда"}
        },
        {
            title: "קישוטי יום העצמאות",
            text: "הקישוטים הם חלק בלתי נפרד מחגיגת יום העצמאות אצלנו במשפחה. בכל שנה, כשמתקרב האירוע, נינה דואגת לקנות קישוטי יום העצמאות מכל המינים והסוגים. הגינה נצבעת בכחול לבן, והאורחים מקבלים תחפושות. בלונים, נצנצים, דגלים, צעיפים ועוד. אבל הדבר הכי חשוב - הם דגלוני ישראל על קיסמים שנמצאים על כל אחת ואחת מצלחות האוכל. האירוע לא יכול להתקיים בלעדיהן, ויהי מה.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F8%2F2_main.webp?alt=media&token=99210a66-7977-4e40-a0fb-ca28ee561e66",
            subImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F8%2F2_sub.webp?alt=media&token=6a4ee5f1-cf51-491c-99c9-0d3c5043331a",
            bottomTitle: {h: "יצירה", r: "Творчество"}
        },
    ],
    9: [
        {
            title: "הכובעים של אבא",
            text: "את ליל הסדר במשפחה אנו חוגגים כל פעם במקום אחר. יש סבב, וכל משפחה מארחת על פי הסדר. כאשר ליל הסדר אצלנו בבית, אבא מוציא את אוסף הכובעים שלו, ומחלק ״כיפות״ לכל מי שרוצה. בסופו של דבר, כל האורחים חובשים לראשם כובעים שונים ממדינות שונות ברחבי העולם.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F9%2F1_main.webp?alt=media&token=cf7ba92c-153b-4a1c-9dab-44683ccc3b8a",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "תלבושות", r: "Одеяния"},
            video: "https://vimeo.com/346184195/bb6e76b13d"
        },
        {//2
            title: "בגדים לבנים",
            text: "בילדותה, אמא ומשפחתה נהגו לחגוג את החגים בסתר, אך לא ויתרו על לבישת בגדים חדשים וחגיגיים בראש השנה ופסח. המסורת הייתה בשביל לציין התחדשות - ולרוב, הבגדים היו לבנים. את המסורת הזו, המשכנו כמשפחה גם בארץ, ועד היום, לפני כל חג, כל אחד מתחדש בבגד או בפריט חדש ולבן לקראת החג, ואני לא מתלוננת על כך.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F9%2F2_main.webp?alt=media&token=c2a4622a-c808-4df6-a7cc-9c737ce285b1",
            subImg: "",
            bottomTitle: {h: "תלבושות", r: "Одеяния"}
        },{//3
            title: "משפחת מלר",
            text: "משפחת מלר היא משפחתה של אחותה התאומה של אמא - אנה. בעלה פיני, וילדיהם, שני עם בעלה ניר וארבעת ילדיהם, נטע עם בעלה אושר ושני ילדיהם, ניצן ואשתו טופז, ודנה, שהיא האחרונה ובת הדודה הקטנה ביותר. אין אירוע או חג בו הם מגיעים בזמן, אלא אם כן הם המארחים. בגלל זה, באופן קבוע, אומרים להם להגיע לפחות שעה לפני השעה שבאמת נקבעה.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F9%2F3_main.webp?alt=media&token=88def712-5152-4eb3-aa52-254e5bfc0063",
            subImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F9%2F3_sub.webp?alt=media&token=4a1d4c7a-30b5-44cf-9b01-fde1a94cf2db",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },{//4
            recipe: true,
            title: "גפילטע פיש",
            text: [
                "2 ק\"ג דג קרפיון טחון נקי מעצמות ועור",
                <br/>,
                "4 בצלים, 2 סלקים, 5 גזרים",
                <br/>,
                "4 כפות קמח מצה",
                <br/>,
                "3 ביצים",
                <br/>,
                "2 שידרה של דג",
                <br/>,
                "7 פלפל אנגלי, 5 עלי דפנה",
                <br/>,
                "1 כף סוכר",
                <br/>,<br/>,
                "1. לטגן בצל ולטחון במטחנה יחד עם גזר.",
                <br/>,
                "2. להוסיף לדג הטחון בצל, גזר, קמח מצה, וביצים.",
                <br/>,
                "3. להכניס את התערובת למקרר לפחות לשעה.",
                <br/>,
                "4. להוציא מהמקרר וליצור קציצות קצת פחוסות.",
                <br/>,
                "5. בסיר רחב, לפרוס עיגולי בצל, גזר וסלק ולהוסיף עלי דפנה, פלפל אנגלי, מלח וסוכר. לסדר את השידרה בדפנות הסיר ולהביא לרתיחה.",
                <br/>,
                "6. להחליק את קציצות הדגים אל הסיר עם הרוטב.",
                <br/>,
                "7. לבשל על אש קטנה כ-40 דק'.",
            ],
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F9%2F4_main.webp?alt=media&token=b92a1429-748f-44a7-ac28-4053277745a7",
            subImg: "",
            bottomTitle: {h: "אוכל", r: "Еда"}
        },{//5
            title: "הבדיחות של אבא",
            text: "אבא אוהב לספר בדיחות. הוא גם באמת מכיר המון בדיחות טובות. את ההומור " +
            "שלי כנראה ירשתי ממנו. רוב הבדיחות הן ברוסית ומבחינתו זו לא בעיה, לכן הוא פשוט מתרגם אותן לעברית. הבעיה היא, שהוא לא הבין עד היום, שברגע שהבדיחות מתורגמות לעברית, אף אחד לא מבין אותן, ואנחנו פשוט מחייכים ומחייכות בנימוס. או פשוט צוחקים מהעובדה ששוב הפעם, לא הבנו כלום מהבדיחה.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F9%2F5_main.webp?alt=media&token=2a9ceb04-280f-45a7-9f2a-bd58e2a65128",
            subImg: "",
            bottomTitle: {h: "בדיחות", r: "Шутки"}
        },
    ],
    10: [
        {
            video: "fly",
            title: "מוחה צקטוחה",
            text: "מוחה צקטוחה היא אגדת ילדים, שהתפרסמה לראשונה ברוסיה. את האגדה סיפרו להוריי בתור ילדים, והם סיפרו את האגדה לאחותי ולי, ואחותי מספרת אותה לילדיה. העלילה מספרת על מוחה צקטוחה, זבובה אשר נקלעת לצרה צרורה, עד שבסופו של דבר, מגיע יתוש אמיץ, עוזר לה, ולבסוף הם מתחתנים בחגיגה גדולה יחד עם שאר החרקים.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F10%2F1_main.webp?alt=media&token=f82d782e-4b23-464d-9b17-2594e7b7ba2f",
            leftImg: "",
            subImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F10%2F1_sub.webp?alt=media&token=e7a98f49-0c09-4acf-a5e8-5f3e72408c85",
            bottomTitle: {h: "סיפורי עם", r: "Народные сказки"}
        },
        {//2
            title: "",
            text: "",
            mainImgRight: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F10%2F2_mainright.webp?alt=media&token=c7705c6a-489b-4491-9a93-43be41a0b2c1",
            mainLeftImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F10%2F2_mainleft.webp?alt=media&token=2e11308b-065e-406b-b16e-62b6e2ca04e9",
            subImg: "",
            bottomTitle: {h: "דו-לשוני", r: "Двуязычный"}
        },{//3
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },{//4
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },{//5
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },
    ],
    11: [
        {
            title: "ליבי",
            text: "הכלבה הראשונה של המשפחה הייתה ליבי. רועה גרמניה מתוקה וחברותית. קיבלנו אותה כשרק נולדה, והיא ליוותה אותי ואת המשפחה בכל דבר שעשינו. היא השתתפה בכמה וכמה תחרויות כלבים, ואף זכתה בהם. ליבי הייתה איתנו 7 שנים, עד שחלתה במחלה ונפטרה. הייתי בכיתה ד׳, והכאב במשפחה היה גדול מאוד. עברו כמה שנים עד שהיינו מסוגלים לצרף למשפחה כלבה חדשה.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F11%2F1_main.webp?alt=media&token=6fcdd287-10f6-43be-91fb-1d932f9c9945",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },
        {//2
            title: "עלמה",
            text: "את עלמה קיבלתי בכיתה יא׳, לכבוד יום הולדתי השבע עשרה. היא הגיעה אלינו גורה קטנה, וגדלה להיות מלאת אנרגיות ושמחת חיים. היא מתוקה להפליא, וחלק בלתי נפרד מהמשפחה. למרות שאמא לפעמים מתעצבנת מכמות השיער שעלמה מנשירה ברחבי הבית, היא מוסיפה המון אהבה ואושר למשפחה. עלמה כבר בת 11, קצת מפונקת, אך אין ספק שהיא צעירה בנפשה.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F11%2F2_main.webp?alt=media&token=6b0b517d-0896-473d-b9ff-688e8bbcd9a4",
            subImg: "",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },{//3
            title: "פסל חרסינה של אבא",
            text: "לפני שסבא מרדכי נפטר, כשאבא היה בן 6, הוא נתן לאבא פסל חרסינה של כלב מסוג רועה גרמני. אבא שמר על הפסל הזה בכל מקום שאליו הגיע, עד שהביא אותו יחד איתו כשעלה לארץ. בגלל פסל החרסינה הזה, לאבא יש אהבה לרועים גרמניים מגיל קטן. זו אחת מהסיבות שלא הייתה שום התלבטות, והיה ברור שכל כלב או כלבה שיצטרפו למשפחה, יהיו מסוג רועה גרמני.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F11%2F3_main.webp?alt=media&token=911a84be-7685-43cf-9e6c-dde19f624197",
            subImg: "",
            bottomTitle: {h: "חפצים", r: "Объекты"}
        },{//4
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },{//5
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },
    ],
    12: [
        {
            title: "חתול שחור",
            text: "כאשר חתול שחור חוצה את הדרך, יש לעצור (גם ברכב אם מתאפשר) ולחכות, עד שמישהו אחר עם פחות מזל ממך יחצה את הדרך. אם הדבר לא מתאפשר, צריך לירוק שלוש פעמים מעבר לכתף. כשלא יכולים לעצור עם האוטו, פותחים חלון ויורקים שלוש פעמים. פעם אחת, חתול שחור חצה את הכביש בזמן שאבא נהג. החלון היה כל כך נקי, שהוא בטעות ירק על החלון כי חשב שהוא פתוח.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F12%2F1_main.webp?alt=media&token=7885f28f-6714-49d6-876d-703e19f98777",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "אמונות טפלות", r: "Суеверия"}
        },
        {//2
            title: "פתיחת מטריה",
            text: "אסור לפתוח מטריה בתוך הבית. אם פותחים מטריה בתוך הבית, זה יביא למזל רע. לכן, רק כשעוברים את מפתן הדלת, ניתן לפתוח את המטריה ולהמשיך את היום." +
            "במקרה ובטעות נפתחה מטריה בין קירות הבית, סוגרים אותה מיד, מוסיפים טפו, טפו, טפו, וממשיכים הלאה.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F12%2F2_main.webp?alt=media&token=0a4bc256-2a41-4e52-80d9-4fac1a6b5010",
            leftImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F12%2F2_left.webp?alt=media&token=fdf823bf-c92e-408d-b4f5-a3b375dc53d8",
            bottomTitle: {h: "אמונות טפלות", r: "Суеверия"}
        },{//3
            title: "צרור מפתחות",
            text: "אסור לשים צרור מפתחות על השולחן כי אז לא יהיה בבית כסף. את אמונה טפלה זו, גיליתי רק בגיל מאוחר. בילדותי לא שמתי לב לכך. אבא לא שם את צרור המפתחות שלו על השולחן עד היום, ואם הוא רואה צרור מפתחות של מישהו על השולחן, הוא מניח אותו במקום אחר.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F12%2F3_main.webp?alt=media&token=87863afc-0d7c-4d01-a3dd-3218719a3e54",
            subImg: "",
            bottomTitle: {h: "אמונות טפלות", r: "Суеверия"}
        },{//4
            title: "גרביים בתוך הבית",
            text: "אסור ללכת עם גרביים כי זה מביא למזל רע ולא יביא כסף הבייתה. בנוסף, האמונה אומרת שללכת עם גרביים בבית, זו היא פעולה שעושים כמנהג אבלות. בבית אנחנו הולכים או יחפים או עם נעליים. לכל אחד ואחת מבני המשפחה יש את נעלי הבית שלו. גם לי יש, אך בד״כ, אני מעדיפה ללכת יחפה.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F12%2F4_main.webp?alt=media&token=c54141b9-efea-441a-b516-e4fa1a2e34c6",
            subImg: "",
            bottomTitle: {h: "אמונות טפלות", r: "Суеверия"}
        },{//5
            title: "שריקות בתוך הבית",
            text: "סבתא גניה הייתה מספרת שלפי האמונה, אסור לשרוק בתוך הבית כי זה מביא למזל רע ולא יביא כסף הביתה. האמת היא, כשאני חושבת על זה, באמת לא שמעתי את בני המשפחה שלי שורקים בתוך הבית. אולי זה בגלל שאני בכלל לא יודעת לשרוק, אז הדבר מעולם לא הפריע לי או משך את תשומת לבי.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F12%2F5_main.webp?alt=media&token=0f711a63-3c0f-4022-a8c7-926ef6f75b38",
            subImg: "",
            bottomTitle: {h: "אמונות טפלות", r: "Суеверия"}
        },
    ],
    13: [
        {
            recipe: true,
            video: "https://vimeo.com/346194325/3c36cdf13c",
            title: "מרק סלק",
            text: [
                "3 בקבוקים של מיץ סלק טבעי אורגני או מרק בורשט",
                <br/>,
                "6 נקניקיות מבושלות (לא חובה)",
                <br/>,
                "12 מלפפונים בייבי",
                <br/>,
                "2 שמנת חמוצה, 8 צנוניות",
                <br/>,
                "4 ביצים קשות",
                <br/>,
                "בצל ירוק",
                <br/>,
                "שמיר",
                <br/>,
                "קצת מיץ לימון או חומץ",
                <br/>,<br/>,
                "1. לחתוך את המלפפונים ואת הצנוניות לעיגולים.",
                <br/>,
                "2. להוסיף שמנת חמוצה.",
                <br/>,
                "3. לשפוך מעט מהמיץ סלק או מהבורשט ולערבב היטב כדי שלא יהיו גושים של שמנת.",
                <br/>,
                "4. לשפוך את שאר הנוזל, להוסיף בצל ירוק ושמיר, מלח לפי הטעם וקצת מיץ לימון או חומץ.",
                <br/>,
                "5. להגיש עם חצאי ביצה קשה. בתיאבון!",
            ],
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F13%2F1_main.webp?alt=media&token=bb4cdd46-9608-42d3-aeb1-bb2bcbc2cbfb",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "אוכל", r: "Еда"}
        },
        {//2
            title: "סבתא ג’ניה",
            text: "נולדה בסוף מאי 1911, ברוסיה בעיר קלימוב. סבתא גניה עלתה לארץ בשנת 1981, 4 שנים לאחר שאבא שלי עלה לארץ. אחד הזכרונות של אבא מתקופת ילדותו בחורף הקר ברוסיה, היא כשהוא מגיע הביתה מהלימודים, ואמו מבשלת סירי מרק חמים גדולים ומפתים. משם נולדה אהבתו למרקים, שאותה הוא העביר גם אלינו.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F13%2F2_main.webp?alt=media&token=7c20d13d-e803-4ace-bc57-9b9c27be317c",
            subImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F13%2F2_sub.webp?alt=media&token=d75603f6-c4de-4b1f-9b04-abe15b281cca",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },{//3
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },{//4
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },{//5
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },
    ],
    14: [
        {
            title: "בלינצ’יקי",
            text: [
                "20 עלי בלינצ'ס של חב' מעדנות או עלי בלינצ'ס תוצרת עצמית",
                <br/>,
               '½ ק"ג בשר או גבינה',
                <br/>,
                "2 בצלים גדולים",
                <br/>,
                "מלח/פלפל לפי הטעם",
                <br/>,<br/>,
                "1. לטגן בצל ובהמשך להוסיף את הבשר הטחון ולטגן עד שמחליף צבע.",
                <br/>,
                "2. להוסיף מלח ופלפל לפי הטעם.",
                <br/>,
                "3. לשים כף של בשר בחלק התחתון של עלה הבלינצ'ס" +
                "ולקפל בצורת מעטפה.",
                <br/>,
                "4. לטגן קלות מכל צד ולהגיש. בתיאבון!",
                <br/>,
                "ניתן להחליף את הגבינה לפטריות, בשר או תפוח אדמה, לפי מה שאוהבים.",
            ],
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F14%2F1_main.webp?alt=media&token=d7d2a36b-0370-4787-866e-058ccf7e9068",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "אוכל", r: "Еда"}
        },
        {//2
            title: "קישוט יולקה",
            text: 'נובי גוד, "השנה החדשה”, הוא חג לציון תחילתה של השנה האזרחית. אמנם כמשפחה לא עשינו חגיגה גדולה כל שנה, אך אנו תמיד מציינים את החג, גם אם באירוע קטן. אחד ממנהגי החג, הוא קישוט היולקה, באורות, כדורי זכוכית וכוכב בראש היולקה.',
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F14%2F2_main.webp?alt=media&token=1fbdadca-9433-4331-85c2-12918b010705",
            subImg: "",
            bottomTitle: {h: "יצירה", r: "Творчество"}
        },
    ],
    15: [
        {
            title: "מרפקים",
            text: "זה הוא אחד ממנהגי הנימוסים החשובים ביותר. בזמן ארוחה, כאשר המשפחה יושבת מסביב לשולחן האוכל, אסור להניח מרפקים על השולחן, וזאת מכיוון שהדבר הוא חוסר נימוס מוחלט לשאר יושבי השולחן. אני מעולם לא הצטיינתי בקיום מנהג זה, וקיבלתי המון הערות על כך, בהחלט.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F15%2F1_main.webp?alt=media&token=f5433ee0-7c15-49df-ba16-405b5171eb08",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },
        {//2
            title: "צליל ערבוב",
            text: "בדרך כלל בסוף ארוחה משפחתית, או סתם באחה״צ נעימים, אנו מתיישבים מסביב לשולחן בגינה לשתות שתייה חמה יחד עם משהו מתוק לאכול. על השולחן מחכה מגש עם מים חמים, תה, קפה, כוסות וכפיות. לאחר שכל אחד מוזג לעצמו את השתייה שלו, מגיע חלק חשוב ביותר - ערבוב השתייה. בעת הערבוב, צריך להמנע עד כמה שאפשר, מצליל הכפית המתנגשת בכוס. הדבר נחשב לחוסר נימוס גדול מאוד. המשימה אמנם נשמעת פשוטה, אך במציאות, לא כל כך.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F15%2F2_main.webp?alt=media&token=05642e00-6ae3-4481-8e68-6fe1586a347c",
            subImg: "",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },{//3
            title: "קולות לעיסה",
            text: "המשפחה שלי אוהבת לאכול. אהבה שעברה מדור לדור ללא ספק. שולחן האוכל תמיד מלא בכל טוב, ואף אחד לא נשאר רעב. אחד מהדברים שחשוב להקפיד עליהם בעת האכילה, הוא להמנע מהשמעת קולות לעיסה. או לפחות, לנסות להסוות זאת עד כמה שאפשר.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F15%2F3_main.webp?alt=media&token=12f1cda1-eeaf-472e-9c66-2fc5357c91a3",
            subImg: "",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },{//4
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },{//5
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },
    ],
    16: [
        {
            video: "https://vimeo.com/346258801/30843cf1fb",
            title: "ישיבות פרלמנט",
            text: "אחד מהדברים שחוזרים על עצמם כמעט בכל מפגש משפחתי, היא שיחת הפוליטיקה. המשפחה שלי מלאה בדעות שונות ומגוונות, וזה תמיד מעניין לשמוע מה כל אחד חושב מנקודת המבט שלו. לפעמים השיחה רגועה, לפעמים היא מתלקחת במהירות, אך מה שבטוח, המסקנה היא תמיד אותה המסקנה - נסכים שלא להסכים. בסופו של דבר, כולם מתחבקים, ומדברים על תאריך למפגש הבא.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F16%2F1_main.webp?alt=media&token=e9684345-5db5-45e7-a60c-63fbd112e61a",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },
        {//2
            title: "בולקה",
            text: "מרים, היא אחותה הגדולה של אמא. כשהייתה קטנה, לבולקה היו לחיים שמנמנות, ומאז מכנים אותה בולקה, שזה קיצור לבולצ’קה - לחמנייה בשפה הרוסית. בולקה, היא דמות בולטת במשפחה. לא מפספסת שום אירוע או חגיגה, ומאוד דעתנית. היא ״ליכודניקית בנשמה״ כפי שהיא אומרת, ואת זה אף אחד לא יפספס. אני אוהבת אותה מאוד, ואפשר להגיד, שבלעדייה היה פחות מצחיק במשפחה, בלי שום צל של ספק.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F16%2F2_main.webp?alt=media&token=8d6b6e94-fdb8-4458-9910-99747e56e06e",
            subImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F16%2F2_sub.webp?alt=media&token=ea962079-2884-4c5e-a93c-2bc8206e40dc",
            bottomTitle: {h: "דעות", r: "Взгляды"}
        },{//3
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },{//4
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },{//5
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },
    ],
    17: [
        {   
            recipe: true,
            title: "מתכון לפילמני",
            text: [
                "1 ביצה",
                <br/>,
                "600 גר' קמח",
                <br/>,
                "1 כפית מלח",
                <br/>,
                "½ ק\"ג בשר בקר",
                <br/>,
                "2 בצלים",
                <br/>,<br/>,
                "1. להכניס לתוך הקמח ביצה ומים במנות קטנות.",
                <br/>,
                "ללוש בצק עד שיהיה אחיד. להעביר לקערה ולכסות.",
                <br/>,
                "2. לטחון בשר עם בצל.",
                <br/>,
                "3. לשטח את הבצק לעובי של 2 מ\"מ ולחתוך",
                <br/>,
                "עיגולים בקוטר של כ-6 ס\"מ.",
                <br/>,
                "4. לשים בכל עיגול בשר ולקפל לחצי.",
                <br/>,
                "5. להכניס את הפלמני למקפיא לפחות לשעה.",
                <br/>,
                "6. להרתיח מים בסיר עם קצת שמן ולהחליק את",
                <br/>,
                "הפלמני פנימה עד שהם צפים.",
                <br/>,
                "7. לשים בצלחת, להוסיף שמנת, חמאה וחומץ וקצת",
                <br/>,
                "פלפל שחור לפי הטעם. בתיאבון!",
            ],
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F17%2F1_main.webp?alt=media&token=ec5c341d-d1e5-4d4c-aa74-482df9855246",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "אוכל", r: "Еда"}
        },
        {//2
            title: "לאכול מהסיר",
            text: "אבא מספר שברוסיה, לא היו מקררים, לכן בישלו סירי אוכל ואפו אוכל בתנור, וישר הגישו אל השולחן. המנהג הזה דבק בו, ולמרות שכבר יש מקררים, אבא לימד אותנו, שתמיד יותר טעים לאכול ישר מהסיר. הבעיה מתחילה, כשאמא מבשלת, ואנחנו לא מתאפקים, אז טועמים מהסיר עוד בזמן ההכנה. היא ממש שונאת את זה, אבל כמו שאבא אומר, אין מה לעשות.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F17%2F2_main.webp?alt=media&token=d2aa7c53-066e-4b29-ba2c-e9ad07afc6d5",
            subImg: "",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },{//3
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },{//4
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },{//5
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },
    ],
    18: [
        {
            title: "מתכון לסלט אוליבייה",
            text: [
                "4 תפו\"א אדום",
                <br/>,
                "4 גזר",
                <br/>,
                "3 ביצים",
                <br/>,
                "1 קופסה של אפונה ירוקה",
                <br/>,
                "1 קופסה של מלפפונים במלח",
                <br/>,
                "150 גר' נקניק מבושל דוקטורסקאיה (חנות רוסית) או מורטדלה (סופר)",
                <br/>,
                "בצל ירוק",
                <br/>,
                "5 כפות מיונז",
                <br/>,
                "מלח/פלפל שחור לפי הטעם",
                <br/>,<br/>,
                "1. לבשל ביחד תפו\"א, גזר וביצים (הכל עם קליפה) עד שמוכנים, אבל לא רכים מידי. עדיף לקרר במשך הלילה ואז להוריד את הקליפה.",
                <br/>,
                "2. לחתוך תפו\"א, גזר, ביצים ומלפפון במלח לריבועים קטנים.",
                <br/>,
                "3. להוסיף אפונה ירוקה, בצל ירוק ומיונז. לטבל במלח ופלפל שחור.",
                <br/>,
                "4. זהירות עם המלח, כי גם מיונז מלוח. בתיאבון!",
            ],
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F18%2F1_main.webp?alt=media&token=216212ae-2553-4f96-b7c9-8992070dd400",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "אוכל", r: "Еда"}
        },
        {//2
            title: "מקורות הירקון",
            text: "המשפחה שלי היא משפחה שאוהבת להפגש ולבלות יחד. ימי הולדת, חגים, או סתם סוף שבוע אביבי ונעים, הם תמיד סיבה למפגש פיקניק משפחתי. מבין כל הפינות שנפגשנו בהם, המפורסמת ביותר היא הפינה שלנו בפארק מקורות הירקון. הרבה זמן מהילדות שלי, ביליתי שם עם כל המשפחה המורחבת.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F18%2F2_main.webp?alt=media&token=25c66c7a-7b60-4f29-b540-9aee7b6791a9",
            subImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F18%2F2_sub.webp?alt=media&token=76a25548-1f58-4030-a874-7af3b45653e9",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },
    ],
    19: [
        {
            video: "https://vimeo.com/346724819/cee8790894",
            title: "קיר הצילומים בבית",
            text: "ממש בצמוד לחדרים של אחותי נינה, ושלי, נמצא קיר התמונות המשפחתי. אוסף צילומים, משני הצדדים - גם של אמא וגם של אבא. לכל צילום יש סיפור שלם מאחוריו, ואבא ואמא תמיד נהנים להזכר, לספר ולשתף בזכרונות שלהם. הקיר כבר הפך לחלק בלתי נפרד מהנוף הביתי שלנו, וכל מי שיגיע אלינו, לא יפספס אותו.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F19%2F1-main.webp?alt=media&token=d14e8c92-d4fa-40f7-a841-3af8c50310c6",
            leftImg: "",
            subImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F19%2F1_sub.webp?alt=media&token=51fb99f8-cadd-4e8d-92ff-be13fe7d3e28",
            bottomTitle: {h: "צילום", r: "Фотографирование"}
        },
        {//2
            title: "פורטרטים",
            text: "הרבה מן הצילומים שתלויים על הקיר, הם פורטרטים של המשפחה שלי. באופן אישי, תמיד מצאתי את עצמי נמשכת לצילום דיוקנאות. לתחושתי, מצד אחד יש בדיוקן אינטימיות ומסתורין, ומצד שני, פתיחות ועולם שלם שנגלה לפנייך. מצאתי את עצמי מתבוננת בתצלומי המשפחה שעל הקיר כבר כמה וכמה פעמים, שואלת שאלות ותוהה, איך היה, אם הייתי מכירה את כל הפרצופים האלה באופן אישי.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F19%2F2_main.webp?alt=media&token=5050375c-1bcf-427e-b6e6-a5af42ad5afe",
            subImg: "",
            bottomTitle: {h: "צילום", r: "Фотографирование"}
        },
    ],
    20: [
        {
            video: "https://vimeo.com/346346894/e0fab3d33d",
            title: "הארכיון של אמא",
            text: [
                "אמא שלי, פאני, נולדה ביוני 1958, בריגה שבלטביה. היא נולדה חצי שעה אחרי אחותה התאומה אנה, ועשר שנים אחרי אחותה הגדולה מרים. לאחר שעלתה ארצה, החלה ללמוד בבית הספר לאמנות ע״ש תלמה ילין במגמת אמנות. לאחר מכן התגייסה ושירתה בחיל המודיעין, ועם סיום שירותה הצבאי, התקבלה לאקדמיה לאמנות ועיצוב ״בצלאל״ ולמדה בפקולטה לעיצוב גרפי. כיום היא עוסקת בעיצוב גרפי ובנוסף, מורת דרך לקבוצות המטיילות בארץ.",
                <br/>,<br/>,
                "אחד מהדברים שמאפיינים את אמא, הוא סדר וארגון. הדבר מתבטא בכל דבר שהיא עושה, שאחד מהם, הוא תיוק של כל המסמכים, ציורים, תעודות וברכות של בני המשפחה בקלסרים. זה בהחלט תמיד כייף להציץ בהם ולהזכר."
            ],
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F20%2F1_main.webp?alt=media&token=279fb0ce-6270-4f5b-8dac-0d0a242551a1",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "מנהגים", r: "Традиции"}
        },
        {//2
            title: "ציורי ילדות",
            text: "בין כל המסמכים, תעודות וברכות שנמצאים בקלסרים, אפשר להבחין שרוב הניילונים, שומרים על ציורים מהילדות של נינה, שלי, וגם של אחייניות שלי, אלה ונעמה. מעניין אותי תמיד לחזור ולהסתכל, ולראות איך העולם היה נראה מנקודת המבט שלי בתור ילדה, והאם הוא נראה דומה או שונה מאיך שאני רואה אותו היום. מעניין גם לראות את קווי הדמיון והשוני בין הציורים שלי, לציורים של נינה ולציורים של אלה ונעמה, הבנות שלה.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F20%2F2_main.webp?alt=media&token=45d520a2-4dbd-4658-9d84-0069b2683b18",
            subImg: "",
            bottomTitle: {h: "יצירה", r: "Творчество"}
        },{//3
            title: "",
            text: "",
            mainImg: "",
            mainCenterImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F20%2F3_maincenter.webp?alt=media&token=0848a146-d9b1-46bd-bbf1-23b92b5387b2",
            subImg: "",
            bottomTitle: {h: "דו-לשוני", r: "Двуязычный"}
        },{//4
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },{//5
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },
    ],
    21: [
        {
            video: "https://vimeo.com/346185443/dca3906231",
            title: "דייסת סולת עם סמיילי ריבה",
            text: [
                "1 כוס חלב",
                <br/>,
                "1 כוס מים",
                <br/>,
                "3\\2 כפות סוכר",
                <br/>,
                "1 כוס סולת",
                <br/>,
                "ריבת תות שדה לקישוט",
                <br/>,<br/>,
                "1. מרתיחים חלב עם מים.",
                <br/>,
                "2. מכניסים את הסולת בזרם דקיק אל תוך הסיר.",
                <br/>,
                "3. מוסיפים סוכר ומערבבים.",
                <br/>,
                "4. אחרי שהדייסה מסמיכה, מורידים מהאש.",
                <br/>,
                "5. מחלקים לקעריות ומוסיפים ריבה בכמות הרצויה, בצורת סמיילי מחייך.",
            ],
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F21%2F1_main.webp?alt=media&token=7cf80bb2-2a3a-4ad3-b437-45fb2f9efcdb",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "אוכל", r: "Еда"}
        },
        {//2
            title: "סבתא נינה",
            text: [
                "סבתא נינה נולדה בשנת 1920, בעיר לוגנסק באוקראינה, ובהמשך התגוררה והקימה את משפחתה בריגה, לטביה. כאשר עלתה לארץ עם משפחתה במרץ, 1974, חג הפורים, התגברה על קשיים רבים, ביניהם למידת השפה העברית לאחר 30 שנה בריגה. שנה אחת בלבד לאחר עלייתה ארצה, סבתא נינה דיברה עברית. בהמשך, עבדה בעיריית תל אביב.",
                <br/>,<br/>,
                "חלומה התגשם, ובנותיה, (אשר עלו יחד איתה ועם סבא משה בעלה), גדלו במדינה יהודית ואף הקימו כאן את משפחותיהן. סבתא נינה הייתה שרה ומספרת את ״סבתא בישלה דייסה״ לאמא, ואמא סיפרה ושרה לנו."
            ],
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F21%2F2_main.webp?alt=media&token=9562037c-341e-4572-98bf-ddeecad80652",
            subImg: "",
            bottomTitle: {h: "שירי ילדים", r: "Детские песни"}
        },{//3
            title: "סכו״ם כסף",
            text: [
                "סכו״ם הכסף עבר במשפחה מדור לדור ועשוי מכסף אמיתי. ליהודים במזרח אירופה לא היה ניתן לקחת חפצים מסוג זה כאשר עלו ארצה. רוב הכלים המשפחתיים נשאר בבריה״מ ובלטביה, ומה שלאחר מכן יכלו להביא, הביאו.",
                <br/>,
                "בין כלי הכסף של משפחת ינקלוביץ’, ישנם כלים שבולקה היתה סוחבת בתיק כדי שלא יגנבו לה מהבית, ואת הכפות הכסופים, נינה קיבלה כמתנה לנישואיה בחודש מרץ 2012."
            ],
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F21%2F3_main.webp?alt=media&token=f9be6e94-bf5c-4569-aa3b-61d56752d892",
            subImg: "",
            bottomTitle: {h: "חפצים", r: "Объекты"}
        },{//4
            title: "",
            text: "",
            mainImgRight: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F21%2F4_mainright.webp?alt=media&token=a7cc73b9-1ec6-46f6-96f8-d7c14cc30de9",
            mainLeftImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F21%2F4_mainleft.webp?alt=media&token=9b08a021-4fd4-4882-8dbc-be6c93a9a1a7",
            subImg: "",
            bottomTitle: {h: "דו-לשוני", r: "Двуязычный"}
        },{//5
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },
    ],
    22: [
        {
            title: "כפית תוכי",
            text: "במשפחתה של אמי היה נהוג להביא כפית כסף כאשר תינוק או תינוקת מגיעים לעולם ומצטרפים למשפחה. את הכפית הזו, קיבלו הורי של אמי, כאשר אמא ואחותה התאומה אנה, נולדו. הכפית היפה הזו נמצאת אצלנו עד היום, והיא אהובה עליי מאוד.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F22%2F1_main.webp?alt=media&token=7d7140fc-a272-43d3-89f2-ce7031f7b17a",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "חפצים", r: "Объекты"}
        },
        {//2
            title: "שעון זהב",
            text: "את שעון הזהב הזה קיבלתי מאבא לפני כמה שנים, ומאז הוא לא יורד מהיד שלי. השעון עצמו, הגיע יחד עם אבא כאשר עלה לארץ. הוא אינו זוכר מה הם המקורות שלו, אך יודע שהוא עובר במשפחה כבר שנים רבות. כאשר הגיע לארץ, מצא חנות ענתיקות ובה צמיד זהב, שאותו חיבר לשעון. שעון היד הזה, הוא אחד החפצים האהובים עלי, ובעל משמעות גדולה מבחינתי.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F22%2F2_main.webp?alt=media&token=2d3537fa-d349-4432-b1e8-24e9894e847a",
            // leftImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F22%2F2_left.webp?alt=media&token=62fc2e21-68c1-4d41-90e3-8bb51693ee20",
            bottomTitle: {h: "חפצים", r: "Объекты"}
        },{//3
            title: "פסנתר",
            text: "הפסנתר נקנה בריגה בשביל בולקה, ועלה לארץ יחד עם המשפחה של אמי, לדירה שלהם בחולון. בעזרת מנוף, העלו אותו דרך החלון אל תוך הבית. הפסנתר עמד הרבה שנים לאחר מכן אצל אנה, אחותה התאומה של אמא במחסן. רגע לפני שנזרק או שעשו ממנו בר, בתחילת שנות האלפיים, הוחלט לשפץ אותו, ואז התגלה כל יופיו. מתחת לשכבות הצבע, התגלו פנינים וסוגי עץ שונים שהיו מוסתרים. היום הפסנתר נמצא אצלנו בבית, ומדי פעם, מישהו או מישהי מבני המשפחה מתיישבים לנגן, וזה תמיד כיף.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F22%2F3_main.webp?alt=media&token=a32289d6-d596-4ca0-96c2-4cf794aa6703",
            subImg: "",
            bottomTitle: {h: "חפצים", r: "Объекты"}
        },{//4
            title: "מכונת כתיבה",
            text: "מכונת הכתיבה היא אחד מהחפצים המשפחתיים היפים בעיניי שנמצאים בבית הוריי. היא הגיעה יחד עם אבא שלי כאשר עלה לארץ. הוא רכש אותה בצעירותו ברוסיה, כאשר למד עריכת ספרים, ובתקופה בה לא היו מחשבים ואינטרנט, כך עבר, כתב ולמד. כיום היא אינה בשימוש, אך מונחת על שולחן עץ יפהפה, גלויה לעין, ומביאה ניחוח נוסטלגי לבית.",
            mainImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F22%2F4_main.webp?alt=media&token=ebff89d1-8b8c-4542-8452-cb96b0ca35fb",
            subImg: "",
            bottomTitle: {h: "חפצים", r: "Объекты"}
        },{//5
            title: "שעון",
            text: "השעון החום שנמצא בסלון המשפחתי שלנו, הוא שעון שמלווה אותי עוד מהילדות. הוא נמצא בזכרונות שלי, כמעט בכולם. הוא תמיד היה נמצא ברקע. השעון היה של סבא וסבתא מצד אמא, שקיבלו אותו ביום חתונתם. לאחר שנים רבות שהיה מקולקל - אמא שלחה אותו לתיקון לפני כמה חודשים, ועכשיו הוא שוב עובד. מדי חצי שעה השעון מצלצל ומורה על השעה, והצליל שלו נוסטלגי ונעים.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F22%2F5_main.webp?alt=media&token=13ea7e04-1f04-4351-b0b9-ec923bef761f",
            subImg: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F22%2F5_sub.webp?alt=media&token=dc256c99-e671-46de-ae7a-daeef64b168a",
            bottomTitle: {h: "חפצים", r: "Объекты"}
        },
    ],
    23: [
        {
            title: "חוברת לימוד עברית",
            text: "כאשר שני הורי עלו לארץ, הם היו צריכים ללמוד עברית. אבא הלך לאולפן בחיפה ואמא למדה עצמאית בחופשת הקיץ. בין כל הדברים שהורי שמרו במשך השנים, ישנם מסמכים שונים, חוברות וטקסטים אשר ליוו אותם בתהליך למידת השפה החדשה. בבית אנחנו מדברים רוב הזמן עברית, מלבד כאשר מתארחים אצלנו אורחים דוברי השפה הרוסית, או בשיחה עם בני משפחה שאינם מתגוררים בארץ.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F23%2F1_main.webp?alt=media&token=c07b134a-bfde-4f81-a83c-eb00275ed126",
            leftImg: "",
            subImg: "",
            bottomTitle: {h: "דו-לשוני", r: "Двуязычный"}
        },
        {//2
            title: "המחברת הראשונה שלי",
            text: ["כמובן שגם אני למדתי קרוא וכתוב של השפה העברית, כאשר עליתי לכיתה א׳.",
        <br/>,
    "זה תמיד מעניין לראות את ההבדלים בין תהליך הלימוד שאחותי ואני עברנו, כילידות הארץ, לבין תהליך הלימוד של אבא ואמא, שעלו לארץ מחבר המדינות בשנות השבעים."],
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F23%2F2_main.webp?alt=media&token=89c24b22-782a-4671-890f-229c428f9082",
            subImg: "",
            bottomTitle: {h: "דו-לשוני", r: "Двуязычный"}
        },{//3
            title: "עברית ורוסית",
            text: "בילדותי, הוריי ניסו לשמר את השפה הרוסית אצל אחותי נינה ואצלי. אצל אחותי השפה נשמרה, (במבטא ישראלי כבד), אך אצלי השפה נשכחה. במהלך השנים, התחלתי להתבייש בשפה הזרה שניסו ללמד אותי, ולכן, זנחתי אותה בצד. כיום אני מתחרטת, ואני מנסה להחיות אותה אצלי. אני מבינה המון, אך מתקשה בלדבר, וגם בקרוא וכתוב. אני בטוחה שהשפה שמורה במגירה קטנה במוחי. אני רק צריכה למצוא את אותה מגירה, ולהתחיל להתאמן.",
            mainImgPort: "https://firebasestorage.googleapis.com/v0/b/maya-web-a7109.appspot.com/o/assets%2F23%2F3_main.webp?alt=media&token=c3ce133b-d815-49c4-94d0-7863b0dacedf",
            subImg: "",
            bottomTitle: {h: "דו-לשוני", r: "Двуязычный"}
        },{//4
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },{//5
            title: "",
            text: "",
            mainImg: "",
            subImg: "",
            bottomTitle: {h: "", r: ""}
        },
    ],
};




let mainViews = [];
const TOTAL_WHEEL_PER_PAGE = 15;
let MAX_SCROLL = 0;
class Display extends Component {
    
  constructor(props) {
    super(props);
    this.state = {
        currentScroll: 0,
        DNAScroll: 0
      };
    this.fps = 60;
    let counter = 0;
    const index = this.props.match.params.id-1;
    const cellIndex = this.props.match.params.index;

    if (cellIndex) {
        this.state.currentScroll = (cellIndex-1)*TOTAL_WHEEL_PER_PAGE;
        this.state.DNAScroll = (cellIndex-1)*TOTAL_WHEEL_PER_PAGE;
    }
    for (let k in items[index]) {
      counter += items[index][k].length;
    }
    this.canEditScroll = true;
    MAX_SCROLL = (counter-1)*TOTAL_WHEEL_PER_PAGE;
    this.images = contentData[(index+1)+''].map((page, index) => {
      return {
        mainImg: page.mainImg? (<img src={page.mainImg} className={'mainImg'}/>): null,
        mainImgRight: page.mainImgRight? (<img src={page.mainImgRight} className={'mainImgRight'}/>): null,
        mainLeftImg: page.mainLeftImg?(<img src={page.mainLeftImg}  className={'mainLeftImg'}/>): null,
        mainImgPort: page.mainImgPort?(<img src={page.mainImgPort} className={'mainImgPort'}/>): null,
        mainCenterImg: page.mainCenterImg?(<img src={page.mainCenterImg} className={'mainCenterImg'}/>): null,
        subImg: page.subImg?(<img src={page.subImg} className={'subImg'}/>): null,
        leftImg: page.leftImg?(<img src={page.leftImg} className={'leftImg'}/>): null,
      }
    })
  }

//   componentWillUnmount() {
//         window.onpopstate  = (e) => {
//             }
//     }

  componentDidMount() {
    // window.onpopstate  = (e) => {
    //     const cellIndex = this.props.match.params.index;  
    //     if (cellIndex) {
    //         this.setState({
    //             currentScroll: (cellIndex-1)*TOTAL_WHEEL_PER_PAGE,
    //             DNAScroll: (cellIndex-1)*TOTAL_WHEEL_PER_PAGE
    //         })
    //     }
    // }
    const index = this.props.match.params.id-1;
    this.props.setHover(this.props.items[index].headline);
    
    
  }

  componentWillMount() {
    let id = this.props.match.params.id;
    let content = contentData[id];

    if (content[0].video) {
        this.props.setMenu("play", ()=>{
            this.playVideo(content[0].video);
        });
    } else {
        this.props.setMenu("none", ()=>{});
    }


    mainViews = content.map((item, i)=> {
        this.mainImgView = this.images[i].mainImgPort? 
        <div style={{marginTop: '20.75vh'}}>
                {this.images[i].mainImgPort}
            </div>
        :
        this.images[i].mainImgRight?
        <div style={{ marginTop: '11vh', marginRight: '7.4vw' }}>
            {this.images[i].mainImgRight}
        </div>
        :
        <div style={{ display: 'flex',flexDirection: 'column-reverse', height: '81.5vh' }}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {this.images[i].mainImg}
            </div>
        </div>;

        //Text view!
        let leftSideView =
        <div style={{display: 'flex',width: '35vw', flexDirection: 'column', paddingRight: '2.3vw'}} dir={'rtl'}>
                <div style={{fontFamily: 'FrankG', textAlign: 'right',width: '570px', marginTop: item.recipe? '12.5vh':TEXT_TOP_MARGIN, height: '330px',color: 'black'}}>
                    <div style={{fontSize: "1.2vw", fontWeight: 'bold', lineHeight: 1, textAlign: 'right'}}>
                        {item.title}
                        <br/>
                        <br/>
                    </div>
                    <div dir={'rtl'} style={{fontSize: "1.045vw", fontWeight: 'normal', lineHeight: 1.55, textAlign: 'right'}}>
                        {item.text}
                    </div>
                </div>
                {this.images[i].subImg? 
                <div style={{marginTop: '2vh'}}>
                {this.images[i].subImg}
            </div>: null
            }
                {this.images[i].leftImg?<div dir={'ltr'}>
                    <div style={{bottom: 0, position: 'fixed', marginBottom: '6.7vh', marginLeft: '6.1vw'}}>
                        {this.images[i].leftImg}
                    </div>
                </div>: null}
                
        </div>; 
        let height = document.getElementById("main-img");
        console.log(height);
//         //if recipe. do it like this:
//         leftSideView = (!item.recipe)?leftSideView: <div style={{display: 'flex',width: '35vw', flexDirection: 'column', paddingRight: '2.3vw'}} dir={'rtl'}>
//         <div style={{fontFamily: 'FrankG', textAlign: 'right',width: '570px', marginTop: item.recipe? '11vh':TEXT_TOP_MARGIN, height: '65vh',color: 'black'}}>
//             <div style={{fontSize: "1.2vw", fontWeight: 'bold', lineHeight: 1, textAlign: 'right'}}>
//                 {item.title}
//                 <br/>
//                 <br/>
//             </div>
//             <div dir={'rtl'} style={{fontSize: "1.045vw", fontWeight: 'normal', lineHeight: 1.55, textAlign: 'right'}}>
//                 {item.text}
//             </div>
//         </div>
        
        
// </div>;  




        //End of text view!

        let rightMainImage =
            <div style={{width: '35vw'}}>
            {this.mainImgView}
        </div>;
        leftSideView = item.mainLeftImg?
            <div style={{width: '18.4vw'}}>
                <div style={{marginTop: '11vh'}}>
                {this.images[i].mainLeftImg}
            </div>
            </div> :
            leftSideView;

            let mainView = <>
            {rightMainImage}
            {leftSideView}
            </>;

            mainView = item.mainCenterImg?

                    <div style={{marginRight: '18vw'}}>
                        {this.images[i].mainCenterImg}
                    </div>
                :
                mainView;

      return (<div key={i} style={{display: 'none', position: 'relative', zIndex: 100}}>
               <div
                   style={{
                   color: 'white',
                   zIndex: 5,
                 }}>
                 <div id={'main-content-container'} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>

                     {mainView}

                 </div>

               </div>
             </div>);
    });
  }

    

    handleScroll = e => {
    let element = e.target;
      var number = Math.min(Math.max(parseInt(this.state.currentScroll+e.deltaY/100), 0), MAX_SCROLL);
      let currentPrecentage = number/TOTAL_WHEEL_PER_PAGE-Math.floor(number/TOTAL_WHEEL_PER_PAGE);
      this.setState({
        currentScroll: number,
        DNAScroll: number,
        currentPrecentage: currentPrecentage
      });
    };

    setDNAClicked = (index) => {
        if (this.canEditScroll) {
            let number = index*TOTAL_WHEEL_PER_PAGE;//target number
            let start = index===0? 0.75*TOTAL_WHEEL_PER_PAGE:number  - (TOTAL_WHEEL_PER_PAGE*0.3);
            if (index===0) {
                this.setState({
                    currentScroll: start,
                });
            }

            this.time = 0;
            this.startTime = Date.now();
            this.setState({DNAScroll: number});
            this.canEditScroll = false;
            this.handlerInterval = setInterval(()=>{this.moveDown(500, start, number)}, 1 / this.fps);
            setTimeout(()=> {
                clearInterval(this.handlerInterval);
                this.canEditScroll = true;
            }, 800);
        }
    };

    setCurrentScale = (index) => {
      if (this.canEditScroll) {
        let number = index*TOTAL_WHEEL_PER_PAGE;//target number
          let delta = 0;
          //Handle jump on more then one index
          if (Math.abs(this.state.currentScroll-number) > TOTAL_WHEEL_PER_PAGE*1.5) {
              delta = TOTAL_WHEEL_PER_PAGE*2;
          }
        this.time = 0;
        this.startTime = Date.now();
        this.setState({DNAScroll: number});
        this.canEditScroll = false;
        this.handlerInterval = setInterval(()=>{this.moveDown(2000, this.state.currentScroll + delta, number)}, 1 / this.fps);
        setTimeout(()=> {
          clearInterval(this.handlerInterval);
          this.canEditScroll = true;
        }, 600);
      }
    };
    move = (duration, start, finish) => {
        const elapsed = Date.now() - this.startTime;
        let number = easeInOutQuad(elapsed, start, finish, duration);
        number = Math.min(Math.max(number, 0), MAX_SCROLL);
        let currentPrecentage = number/TOTAL_WHEEL_PER_PAGE-Math.floor(number/TOTAL_WHEEL_PER_PAGE);
        if (number >= finish) {
          number = finish;
          currentPrecentage = number/TOTAL_WHEEL_PER_PAGE-Math.floor(number/TOTAL_WHEEL_PER_PAGE);
          clearInterval(this.handlerInterval);
     }
        this.setState({
          currentScroll: number,
          currentPrecentage: currentPrecentage
        });
    }

    moveDown = (duration, start, finish) => {
      if (start > finish) {
        let delta = Math.abs(start-finish);
        start = 0;
        finish = delta;
      } else {
        this.move(duration, start, finish);
        return;
      }
      const elapsed = Date.now() - this.startTime;
      let number = easeInOutQuad(elapsed, start, finish, duration);
      if (number >= finish) {
        number=finish;
      } else {
        number = this.state.currentScroll - number;
      }
      number = Math.min(Math.max(number, 0), MAX_SCROLL);
      let currentPrecentage = number/TOTAL_WHEEL_PER_PAGE-Math.floor(number/TOTAL_WHEEL_PER_PAGE);
      this.setState({
        currentScroll: number,
        currentPrecentage: currentPrecentage
      });
    };


    generateViews = () => {
        let id = this.props.match.params.id;
        let content = contentData[id];
        let index = Math.min(Math.floor((this.state.currentScroll)/TOTAL_WHEEL_PER_PAGE), content.length - 1);
        
        this.index = index;
        // const cellIndex = this.props.match.params.index;
        // if (this.index != this.prevIndex && cellIndex) {
        //     this.props.history.push(index+1 + '');
        //     this.prevIndex = this.index;
        // }
         
        this.index = index;
        let nextIndex = 0;
        if (index < content.length-1) {
            nextIndex = index + 1;
        }

        let currentView = React.cloneElement(mainViews[index], {
            style: {
                display: this.state.currentPrecentage > 0.75? 'none':'block',
                opacity: this.state.currentPrecentage > 0.5? 1-(this.state.currentPrecentage - 0.45)*4:1,
                transition: 'all 0.5s ease, display 1ms ease 0.5s',
                position: 'relative',
                zIndex: 100,
                height: '790px'
            }
        });
        let nextView = React.cloneElement(mainViews[nextIndex], {
            style: {
                display: this.state.currentPrecentage > 0.75? 'block':'none',
                opacity: (this.state.currentPrecentage - 0.8)*2,
                transition: 'all 0.5s ease',
                position: 'relative',
                zIndex: 100,
                height: '790px'
            }
        });
        return (
            <div style={{height: '790px', position: 'relative'}}>
                {currentView}
                {nextView}
            </div>

        )
    };



    playVideo = (url) => {
        this.videoPlayer = <VideoPlayer url={url} onEnded={()=>{
            this.setState({playVideo: false});
        }}/>
        this.setState({playVideo: true});
    }

    render(){

    let view = this.generateViews();
    let id = this.props.match.params.id;
    return(
    <>
    
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        {this.state.playVideo? this.videoPlayer: null}
        <div onWheel={this.handleScroll}
        dir={'rtl'}
        style={{display: 'flex', flexDirection: 'row'}}>

        
            {/* Emprty DNA Component */}
            <div style={{overflow:"hidden", display: 'flex',marginTop: TEXT_TOP_MARGIN,
             marginRight: '4.4vw',
             marginLeft: '8.8vw',
             flexDirection: 'row', 
             zIndex: 5000, 
             position: 'relative'}}>
                <DNAEmpty {...items[this.props.match.params.id-1]}
                setScale={this.setDNAClicked}
                holder={this.state.DNAScroll}
                singleSize={TOTAL_WHEEL_PER_PAGE}
                />
            </div>

            {view}
            </div>

            <div dir={'rtl'} style={{width: '100%', position: 'absolute', marginTop: '78.8vh'}}>

            <div style={{marginRight: '4.5vw', width: '150px', position: 'absolute'}}>
            {document.body.style.backgroundColor=='rgb(255, 78, 78)'?this.textElement( 'מסורת','Традиции','#190707'): null}
            {document.body.style.backgroundColor=='rgb(180, 117, 117)'?this.textElement( 'אמונות','Убеждения','#190707'):null}
            {document.body.style.backgroundColor=='rgb(255, 156, 154)'?this.textElement( 'נרטיבים','Повествования','#190707'):null}
            {document.body.style.backgroundColor=='rgb(20, 173, 141)'?this.textElement( 'אומנויות','Искусства','#190707'):null}
            {document.body.style.backgroundColor=='rgb(255, 204, 87)'?this.textElement( 'שפה','Язык','#190707'):null}
              </div>
              
              <div className={'rus-font'} dir={'rtl'} style={{marginRight: '57.55vw',position: 'absolute', whiteSpace: 'nowrap',
                    width: '120px',
                    height: '46px',
                    fontSize: '0.835vw',//equal to 16px
                    fontWeight: 500,
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: 1.06,
                    letterSpacing: 'normal',
                    textAlign: 'right',
                    color: 'rgba(0, 0, 0, 0.9)'}}>
                  <div style={{
                   fontFamily: 'Miriam Libre',
                   fontWeight: 'bold'
                  }}>
                      {contentData[id][this.index].bottomTitle.h}
                  </div>
              
              {contentData[id][this.index].bottomTitle.r}

              </div>

            </div>
            <div dir={'rtl'} style={{marginLeft: '4.65vw',marginTop: '80vh',
            position: 'absolute',
            width: '39px',
            height: '32px',
            fontFamily: 'Miriam Libre',
            fontSize: '0.835vw',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.6,
            letterSpacing: 'normal',
            color: '#190707'
            }}>
                {("0" + id).slice(-2)}
              </div>


      </div>

    </>
  );
  }

  textElement = (heb, rus,color) => {
    return (
      <div
      style={{
    width: '150px',
      fontFamily: 'Miriam Libre',
      color: color,
      textAlign: 'right',
      fontWeight: 'bold',
      lineHeight: 1.06,
      cursor: 'default',
    //   marginTop: '1vh',
      fontSize: '0.835vw'}}>
       {heb}
       <div className={'rus-font'} style={{        
         fontWeight: 500}}>
       {rus}
       </div>
    </div>)
  }


}


const mapStateToProps = state => {
    return {
        displayMenu: state.displayMenu,
        menuFunction: state.menuFunction,
        isShowMenu: state.isMenuShown,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setMenu: (id, func) => dispatch({type: Actions.SET_MENU, id: id, func: func}),
        setShowFilterMenu: (isShown) => dispatch({type: Actions.SET_SHOW_FILTER_MENU, isShown: isShown})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Display);