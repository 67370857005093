import React, { Component} from 'react';
import { connect } from 'react-redux';

import FilterTitle from './FilterTitle';
import RegularFilters from './RegularFilters';
import SpecialFilters from './SpecialFilters';
import * as Actions from "../../store/actions";


const specialFilters = [
  {r: 'Одеяния', h: 'תלבושות'},
  {r: 'Еда', h: 'אוכל'},
  {r: 'Традиции', h: 'מנהגים'},
  {r: 'Взгляды', h: 'דעות'},
  {r: 'Суеверия', h: 'אמונות טפלות'},
  {r: 'Народные сказки', h: 'סיפורי עם'},
  {r: 'Детские песни', h: 'שירי ילדים'},
  {r: 'Объекты', h: 'חפצים'},
  {r: 'Творчество', h: 'יצירה'},
  {r: 'Музыка', h: 'מוזיקה'},
  {r: 'Фотографирование', h: 'צילום'},
  {r: 'Шутки', h: 'בדיחות'},
  {r: 'Двуязычный', h: 'דו לשוני'},
];


class FilterMenu extends Component {

  state = {
    width: '0px',
    opacity: 0,
    transitionTime: 1,
    titleHeight: "26px",
    delayTime: '1ms'
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.show !== this.props.show) {
      if (this.props.show) {
        setTimeout(()=>{
          this.setState({opacity: 1, width: '67.5vw', titleHeight: "0px"})
        },5);
        setTimeout(()=>{
          this.setState({delayTime: '0.5s'});
        },100);
        

      } else {
        this.props.setFilterIndex(10);//not exist index.
        setTimeout(()=>{
          this.setState({opacity: 0, width: '0px', titleHeight: "26px"})
        },5);
        setTimeout(()=>{
          this.setState({delayTime: '1ms'});
        },100);
      }
    }


  }
  componentWillUnmount() {
    this.props.setFilterIndex(10);//not exist index.
  }

  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'column', height: '26px'}}>
        <div dir={'rtl'} style={{opacity: this.state.opacity, transition: 'all '+this.state.transitionTime+'s ease '+this.state.delayTime, width: this.state.width, textAlign: 'right', color: "#ece5d9"}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{marginRight: '-2px'}}>
              <FilterTitle heb={"תגיות"} rus={"Закладки"} show={this.props.show} isActive={this.props.filterGroup === 0} index={0} delay={this.state.transitionTime+0}/>
              <div style={{position: "absolute", marginTop: '1.4vh'}}>
                {this.props.filterGroup === 0? <SpecialFilters filters={specialFilters}/>: null}
              </div>
            </div>

            <div>
            <FilterTitle heb={"שם משפחה"} rus={"Фамилия"} show={this.props.show} isActive={this.props.filterGroup === 1} index={1} delay={this.state.transitionTime+0.3}/>
              <div style={{position: "absolute", marginTop: '1.4vh', paddingRight: '3px'}}>
                {this.props.filterGroup === 1? <RegularFilters filters={[{r: 'Клевицкий', h: 'קלויצקי'}, {r: 'Янкелович', h: 'ינקלוביץ’'}]}/>: null}
              </div>
            </div>

            <div>
              <FilterTitle heb={"זמן"} rus={"Время"} show={this.props.show} isActive={this.props.filterGroup === 2} index={2} delay={this.state.transitionTime+0.6}/>
              <div style={{position: "absolute", marginTop: '1.4vh', paddingRight: '3px'}}>
                {this.props.filterGroup === 2? <RegularFilters filters={
                  [{r: 'Первое поколение', h: 'דור ראשון'},
                  {r: 'Второе поколение', h: 'דור שני'},
                  {r: 'Третье поколение', h: 'שלישי'},
                ]}/>: null}
              </div>
            </div>

            <div>
              <FilterTitle heb={"מיקום"} rus={"Расположение"} show={this.props.show} isActive={this.props.filterGroup === 3} index={3} delay={this.state.transitionTime+0.9}/>
              <div style={{position: "absolute", marginTop: '1.4vh', paddingRight: '3px'}}>
                {this.props.filterGroup === 3? <RegularFilters filters={
                  [{r: 'Израиль', h: 'ישראל'},
                  {r: 'Латвия', h: 'לטביה'},
                  {r: 'Россия', h: 'רוסיה'},
                ]}/>: null}
              </div>
            </div>
          </div>

        </div>
        <div style={{
          opacity: this.state.opacity,
          transition: 'all '+this.state.transitionTime+'s ease '+this.state.delayTime,
          width: this.state.width,
          height: '0px',
          border: 'solid 1px #ece5d9'}}>
        </div>
      </div>
  )
  }
}



const mapStateToProps = state => {
  return {
    filterGroup: state.filterGroup
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFilterIndex: (index) => dispatch({type: Actions.SET_FILTER_GROUP, index: index})
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(FilterMenu);
