import React, { Component} from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Landpage from './Landpage';
import Display from '../../components/Display/Display';
import * as Actions from "../../store/actions";
import FilterMenu from "../../components/Filter/FilterMenu";
import { ReactComponent as InfoIcon } from '../../assets/info.svg';

import Info from '../../components/Info/Info';
import { ReactComponent as BackIcon } from '../../assets/back.svg';
import {Icon} from 'antd';
import "./Layout.css"

const items = [
{       //1
      dnaIndex: 0,
      headline: {h: 'אוספים', r: 'Коллекции'},
      tradition:[],
      beliefs:[],
      narratives:[],
      arts:[{height: 25},{height: 25},{height: 25},{height: 25}],
      languages:[],
      regularFilters: [[50,50,0],[0,50,50],[100,0,0]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          0,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        100,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//2
      dnaIndex: 1,
      headline: {h: 'אין לי ארץ אחרת', r: 'У меня нет другой страны'},
      tradition:[],
      beliefs:[],
      narratives:[{height: 60}],
      arts:[{height: 20}],
      languages:[{height: 20}],
      regularFilters: [[50,50,0],[50,50,0],[33.3,33.3,33.3]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          0,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        60,//סיפורי עם
        0,//שירי ילדים
        20,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        20]//דו לשוני
    },
    {//3
      dnaIndex: 2,
      headline: {h: 'ארוחה משפחתית', r: 'Семейная трапеза'},
      tradition:[{height: 50}],
      beliefs:[{height: 15}],
      narratives:[],
      arts:[{height: 35}],
      languages:[],
      regularFilters: [[50,50,0],[0,50,50],[60,0,40]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          50,//מנהגים
        0,//דעות
        15,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        35,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//4
      dnaIndex: 3,
      headline: {h: 'בובות', r: 'куклы'},
      tradition:[],
      beliefs:[],
      narratives:[],
      arts:[{height: 50}, {height: 50}],
      languages:[],
      regularFilters: [[50,50,0],[0,0,100],[100,0,0]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          0,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        100,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//5
      dnaIndex: 4,
      headline: {h: 'הלהקה', r: 'Группа'},
      tradition:[{height: 50}],
      beliefs:[],
      narratives:[],
      arts:[{height: 50}],
      languages:[],
      regularFilters: [[15,85,0],[0,0,100],[100,0,0]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          50,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        0,//יצירה
        50,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//6
      dnaIndex: 5,
      headline: {h: 'טקס נסיעה', r: 'Церемония вождения'},
      tradition:[{height: 55}],
      beliefs:[{height: 45}],
      narratives:[],
      arts:[],
      languages:[],
      regularFilters: [[50,50,0],[33.3,33.3,33.3],[33.3,33.3,33.3]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          55,//מנהגים
        0,//דעות
        45,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//7
      dnaIndex: 6,
      headline: {h: 'יום הולדת', r: 'день рождения'},
      tradition:[{height: 30}],
      beliefs:[],
      narratives:[],
      arts:[{height: 70}],
      languages:[],
      regularFilters: [[45,55,0],[0,0,100],[100,0,0]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          30,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        70,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//8
      dnaIndex: 7,
      headline: {h: 'יום העצמאות', r: 'День независимости'},
      tradition:[{height: 35}],
      beliefs:[],
      narratives:[],
      arts:[{height: 65}],
      languages:[],
      regularFilters: [[35,65,0],[0,0,100],[100,0,0]],
      specialFilters: [
          0,//תלבושות
          35,//אוכל
          0,//מנהגים
          0,//דעות
          0,//אמונוטפלות
          0,//סיפורי עם
          0,//שירי ילדים
          0,//חפצים
          65,//יצירה
          0,//מוזיקה
          0,//צילום
          0,//בדיחות
          0]//דו לשוני
    },
    {//9
      dnaIndex: 8,
      headline: {h: 'ליל הסדר', r: 'Седер ночной'},
      tradition:[{height: 10},{height: 10},{height: 20},{height: 40}],
      beliefs:[],
      narratives:[],
      arts:[],
      languages:[{height: 20}],
      regularFilters: [[35,65,0],[0,0,100],[55,25,20]],
      specialFilters: [
          20,//תלבושות
          20,//אוכל
          40,//מנהגים
          0,//דעות
          0,//אמונוטפלות
          0,//סיפורי עם
          0,//שירי ילדים
          0,//חפצים
          0,//יצירה
          0,//מוזיקה
          0,//צילום
          0,//בדיחות
          20]//דו לשוני
    },
    {//10
      dnaIndex: 9,
      headline: {h: 'מוחה צקטוחה', r: 'муха цокотуха'},
      tradition:[],
      beliefs:[],
      narratives:[{height: 60}],
      arts:[],
      languages:[{height: 40}],
      regularFilters: [[15,85,0],[40,0,60],[0,50,50]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          0,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        60,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        40,//בדיחות
        0]//דו לשוני
    },
    {//11
      dnaIndex: 10,
      headline: {h: 'מוצקי פוצקי', r: 'Моцаки Путцки'},
      tradition:[{height: 33.3},{height: 33.3}],
      beliefs:[],
      narratives:[],
      arts:[{height: 33.3}],
      languages:[],
      regularFilters: [[85,15,0],[25,0,75],[25,0,75]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          66.6,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        33.3,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//12
      dnaIndex: 11,
      headline: {h: 'מזל רע', r: 'Неудача'},
      tradition:[],
      beliefs:[{height: 20},{height: 20},{height: 20},{height: 20},{height: 20}],
      narratives:[],
      arts:[],
      languages:[],
      regularFilters: [[50,50,0],[100,0,0],[0,50,50]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          0,//מנהגים
        0,//דעות
        100,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//13
      dnaIndex: 12,
      headline: {h: 'מרקים', r: 'Супы'},
      tradition:[{height: 50},{height: 50}],
      beliefs:[],
      narratives:[],
      arts:[],
      languages:[],
      regularFilters: [[50,50,0],[50,50,0],[0,50,50]],
      specialFilters: [
          0,//תלבושות
          50,//אוכל
          50,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//14
      dnaIndex: 13,
      headline: {h: 'נובי גוד', r: 'Новый Год'},
      tradition:[{height: 50}],
      beliefs:[],
      narratives:[],
      arts:[{height: 50}],
      languages:[],
      regularFilters: [[50,50,0],[100,0,0],[0,50,50]],
      specialFilters: [
          0,//תלבושות
          50,//אוכל
          0,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        50,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//15
      dnaIndex: 14,
      headline: {h: 'נימוסים', r: 'манеры'},
      tradition:[{height: 33.3},{height: 33.3},{height: 33.3}],
      beliefs:[],
      narratives:[],
      arts:[],
      languages:[],
      regularFilters: [[50,50,0],[100,0,0],[0,50,50]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          100,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//16
      dnaIndex: 15,
      headline: {h: 'פוליטיקה', r: 'политика'},
      tradition:[{height: 15}],
      beliefs:[{height: 85}],
      narratives:[],
      arts:[],
      languages:[],
      regularFilters: [[50,50,0],[0,100,0],[100,0,0]],
      specialFilters: [
          0,//תלבושות
          15,//אוכל
          0,//מנהגים
        85,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//17
      dnaIndex: 16,
      headline: {h: 'פילמני', r: 'Пельмени'},
      tradition:[{height: 50},{height: 50}],
      beliefs:[],
      narratives:[],
      arts:[],
      languages:[],
      regularFilters: [[35,65,0],[100,0,0],[0,50,50]],
      specialFilters: [
          0,//תלבושות
          50,//אוכל
          50,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//18
      dnaIndex: 17,
      headline: {h: 'פיקניק', r: 'пикник'},
      tradition:[{height: 50},{height: 50}],
      beliefs:[],
      narratives:[],
      arts:[],
      languages:[],
      regularFilters: [[35,65,0],[0,0,100],[100,0,0]],
      specialFilters: [
          0,//תלבושות
          50,//אוכל
          50,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//19
      dnaIndex: 18,
      headline: {h: 'קיר תמונות', r: 'Стенные изображения'},
      tradition:[],
      beliefs:[],
      narratives:[],
      arts:[{height: 50},{height: 50}],
      languages:[],
      regularFilters: [[50,50,0],[33.3,33.3,33.3],[33.3,33.3,33.3]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          0,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        0,//יצירה
        0,//מוזיקה
        100,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//20
      dnaIndex: 19,
      headline: {h: 'קלסרים', r: 'Связующие'},
      tradition:[{height: 13}],
      beliefs:[],
      narratives:[],
      arts:[{height: 74}],
      languages:[{height: 13}],
      regularFilters: [[15,85,0],[0,0,100],[100,0,0]],
      specialFilters: [
          0,//תלבושות
          13,//אוכל
          0,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        74,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        13]//דו לשוני
    },
    {//21
      dnaIndex: 20,
      headline: {h: 'קשקה מלשקה', r: 'овсяная каша'},
      tradition:[{height: 38}],
      beliefs:[],
      narratives:[{height: 32}],
      arts:[{height: 10}],
      languages:[{height: 20}],
      regularFilters: [[15,85,0],[33.3,33.3,33.3],[15,70,15]],
      specialFilters: [
          0,//תלבושות
          38,//אוכל
          0,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        32,//שירי ילדים
        10,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        20]//דו לשוני
    },
    {//22
      dnaIndex: 21,
      headline: {h: 'שאריות', r: 'Остаточные'},
      tradition:[],
      beliefs:[],
      narratives:[],
      arts:[{height: 20},{height: 20},{height: 20},{height: 20},{height: 20}],
      languages:[],
      regularFilters: [[50,50,0],[100,0,0],[0,50,50]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          0,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        100,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        0]//דו לשוני
    },
    {//23
      dnaIndex: 22,
      headline: {h: 'שיר האלף בית', r: 'Песня Тысячи Домов'},
      tradition:[],
      beliefs:[],
      narratives:[],
      arts:[],
      languages:[{height: 33.3},{height: 33.3},{height: 33.3}],
      regularFilters: [[50,50,0],[0,50,50],[100,0,0]],
      specialFilters: [
          0,//תלבושות
          0,//אוכל
          0,//מנהגים
        0,//דעות
        0,//אמונוטפלות
        0,//סיפורי עם
        0,//שירי ילדים
        0,//חפצים
        0,//יצירה
        0,//מוזיקה
        0,//צילום
        0,//בדיחות
        100]//דו לשוני
    }
];



class Layout extends Component {

  componentWillMount() {
      // this.setScale();
      // window.onresize = () => {
      //   this.setScale()
      // };
  }

  setHover = (headline) => {
    if (!this.props.isShowMenu) {//Don't allow hover when filter menu is open
      this.setState({
        hoverTextH: headline.h,
        hoverTextR: headline.r
      })
    }

  };

  state = {
    hoverTextH: "",
    hoverTextR: "",
    scaleDec: 1,
    isInfoOpen: false
  };

  // setScale = () => {
  //   var w = window.innerWidth;
  //   var h = window.innerHeight;
  //   let ph = h/1080; //1080 is the original design height!
  //   let pw = w/1920; //1920 is the original design width!
  //   this.setState({scaleDec: Math.min(ph, pw)});
  //   // style={{transform: 'scale('+this.state.scaleDec+','+this.state.scaleDec+')',transformOrigin:'center top'}}
  // }

  menuView = () => (<div style={{
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    }}>
      <div
      style={{color: '#evce5d9',paddingLeft: '4.65vw', paddingTop: '6.3vh'}}>
        <div className={'hoverMe'} style={{cursor: 'crosshair', zIndex: 2000, position: "relative"}}
             onClick={this.props.menuFunction}>
          {this.props.displayMenu}
        </div>
       </div>

       <Route path="/" exact render={(props) =>  
       <div
       ref={elem => this.infoLogoRef = elem}
        style={{
          color: '#evce5d9',
          paddingLeft: !this.props.isShowMenu?'0.7vw':0, 
          paddingTop: '6.3vh', 
          maxWidth: !this.props.isShowMenu?'auto':'0px',
          opacity: !this.props.isShowMenu?1:0,
          transition: !this.props.isShowMenu?'all 1ms 1s' :'all 1ms',
          }}>
          <div style={{cursor: 'crosshair', zIndex: 1000, position: "relative"}}
              onClick={()=> {
                this.setState({isInfoOpen: true});
                this.props.setMenu('exit',()=>{
                  this.setState({isInfoOpen: false});
                  this.props.setMenu("landpage", ()=>{this.props.setShowFilterMenu(!this.props.isShowMenu);});
                });
              }}>
            <InfoIcon className={'hoverMe'} style={{userSelect: 'none'}} height={'4.6vh'}/>
        </div>
       </div>
     
      } />


      {/* maxHeight: '2.4vh', */}
        <div style={{paddingTop: "6vh", userSelect: 'none', zIndex: 1, position: "relative"}}>
        <Route path="/" exact render={(props) =>  <FilterMenu show={this.props.isShowMenu}/>} />

        </div>
        
      </div>);

  render() {


    setTimeout(()=> {
      if (this.infoLogoRef) {
        this.infoLogoRef.style.transition = '';
      }
      
    },1500);
    return (
      <div>
      <div style={{
      display: 'flex',
      position: 'relative',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      }}>

      
      {this.menuView()}
      


      <Route path="/" exact render={(props) =>  <div>{this.renderNameTitle('#ece5d9')}</div>} />
      <Route path="/:id" render={(props)=>  <div>{this.renderNameTitle('#190707')}</div>} />

    {this.state.isInfoOpen? <Info/>: null}

      </div>
        <Route path="/" exact render={(props) => <Landpage {...props} items={items} setHover={this.setHover}/>} />
        <Route path="/:id/" exact render={(props)=> <Display {...props} items={items} setHover={this.setHover}/>} />
        <Route path="/:id/:index" render={(props)=> <Display {...props} items={items} setHover={this.setHover}/>} />

      <div style={{
          position:'absolute',
          right: 0,
          top: 0,
          marginRight: '55.5vw',
          marginTop: '5.5vh',
}}>


        
     
      <Route path="/" exact render={(props) =>  <div>{this.renderCenterTitle('#ece5d9', false)}</div>} />
      <Route path="/:id" render={(props)=>  <div>{this.renderCenterTitle('#190707', true)}</div>} />

      </div>

      <Route path="/" exact render={(props) =>  <div style={{fontSize: '0.835vw',paddingLeft: '5vw', paddingTop: '5px', color: '#ece5d9', width: '100%', textAlign: 'left', direction: 'rtl', fontFamily: 'Miriam Libre'}}>
          נבנה ע"י <a style={{textDecoration: 'none', color: '#ece5d9', fontWeight: 'bold'}} href='https://sympl.co.il/'>SYMPL</a>
          </div>} />
      </div>
      
  )
  }

  renderNameTitle = (color) => {
    return (
      <Link to={'/'} style={{textDecoration: 'none', cursor: 'crosshair'}}>
             <div style={{
      display: 'flex',
      flexDirection: 'column',
      marginRight: '4.3vw',
      position: "relative",
      zIndex: 9000
      }}>
        <div style={{
          color: color,
         marginTop: '5.5vh',
        //  height: '4vh',
         fontSize: '1.302vw',
         textAlign: 'right',
         fontWeight: 'bold',
        //  lineHeight: 1.64,
         fontFamily: 'Miriam Libre'
       }}>
       קלויצקי - ינקלוביץ׳
       </div>
       <div className={'rus-font'} style={{
         color: color,
        height: '2.5vh',
        fontWeight: 500,
        fontSize: '1.25vw',
        letterSpacing: '0px',
        textAlign: 'right'
      }}>
      Клевицкий Янкелович
      </div>
    </div>
      </Link>
 
    )
    
  }

  renderCenterTitle = (color, showBack) => {
    return (<>
    
    
    <div style={{
      display: 'flex',
  flexDirection: 'row',}}>
              
    <div dir={'rtl'} style={{
  display: 'flex',
  flexDirection: 'column',
  whiteSpace: 'nowrap',
  width: '500px'
  }}>
    <div style={{
      color: color,
    //  height: '4vh',
     fontSize: '1.302vw',
     textAlign: 'right',
     fontWeight: 'bold',
    //  lineHeight: 1.64,
     fontFamily: 'Miriam Libre'
   }}>
   {this.state.hoverTextH}
   </div>
   <div className={'rus'} style={{
     color: color,
    height: '2.5vh',
    fontSize: '1.25vw',
    fontWeight: 500,
    letterSpacing: '0px',
    textAlign: 'right'
  }}>
  {this.state.hoverTextR}
  </div>
</div>
<div style={{height: '2vw',width: '2vw',display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',}}>
  {showBack?<Link  style={{textDecoration: 'none', color: color}} to={'/'}>
    {/* <Icon type="right" color={color} size={'1vw'} style={{fontWeight: 'bold'}}/> */}
    <BackIcon className={'hoverMe'} style={{paddingLeft: '13px', cursor: 'crosshair'}} width={'1vw'}/>
   </Link>
  : null
  }
  
</div>



    </div> 
    
    
    </>)
  }
}

const mapStateToProps = state => {
  return {
        displayMenu: state.displayMenu,
        menuFunction: state.menuFunction,
        isShowMenu: state.isMenuShown,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setMenu: (id, func) => dispatch({type: Actions.SET_MENU, id: id, func: func}),
    setShowFilterMenu: (isShown) => dispatch({type: Actions.SET_SHOW_FILTER_MENU, isShown: isShown})
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
