import React, { Component} from 'react';
import ReactPlayer from 'react-player'
import './VideoPlayer.css';
import flyVideo from "../assets/fly.webm";

export default class VideoPlayer extends Component{
    componentDidMount() {
        let video =  document.getElementById('myVideo')
        if (video) {
            video.addEventListener('ended',() => {
                        this.props.onEnded();
                    });
        }
    }
    
    render() {
        let flyView = (<div style={{position: 'fixed', top: 0, zIndex: 9000, minWidth: '100%',
        minHeight: '100%', width: '100%', height: '100%'}}>
        <video id={"myVideo"} width="100%" height="100%" autoplay="autoplay">
            <source src={flyVideo}/>
            Your browser does not support HTML5 video.
        </video>
        </div>);

        let videoView = (
            <div style={{position: 'fixed', top: 0, zIndex: 9000, width: '100%', height: '100%', backgroundColor: 'red'}}>
            <ReactPlayer id={"video-player"} controls={false} width={'100%'} height={'100%'} backgroundColor={'red'} 
                url={this.props.url} volume={0.7} muted={false} config={{
                    vimeo: {playerOptions: {
                        muted: 0,
                        background: true, 
                        transparent: true
                    }}
                
                }} playing onEnded={() => {
                    this.props.onEnded();
                }} />
            </div>
            );

        return ( 
        <div>
            {this.props.url == 'fly'? flyView: videoView}
        </div>
            
        )


    }
}

        