import * as Actions from "./actions";
import React, { Component} from 'react';
import Swing from 'react-reveal/Swing';

import { ReactComponent as MenuLandpage } from '../assets/menu.svg';
import { ReactComponent as ExitMenu } from '../assets/exit.svg';
import { ReactComponent as PlayIcon } from '../assets/play.svg';

const menus = {
  landpage: (<MenuLandpage  height={'4.6vh'}/>),
  exit: (<ExitMenu  height={'4.6vh'}/>),
    play: (
      <Swing forever timeout={1200}>
        <PlayIcon height={'4.6vh'}/>
      </Swing>
    ),
    none: (<></>)
};

const initialState = {
    displayMenu: menus['none'],// left up logo
    menuFunction: ()=>{},
    filterGroup: 10,//the type of the selected filter(filter title selected)
    isMenuShown: false,//if filter is open
    selectedFilter: [
    {isActive: false},
    {isActive: false},
    {isActive: false},
    {isActive: false},
    {isActive: false},
    {isActive: false},
    {isActive: false},
    {isActive: false},
    {isActive: false},
    {isActive: false},
    {isActive: false},
    {isActive: false},
    {isActive: false},
  ],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_MENU:
      let menu = menus[action.id];
      if (menu) {
        return {...state, displayMenu: menu, menuFunction: action.func}
      } else {
        return {...state};
      }
      break;
      case Actions.SET_FILTER_GROUP:
          return {...state, filterGroup: action.index}
        break;
      case Actions.SET_SHOW_FILTER_MENU:
        return {...state, isMenuShown: action.isShown}
      break;
      case Actions.RESET_FILTERS_SELECTED:
        return {...state, selectedFilter: initialState.selectedFilter}
      break;
      case Actions.TOGGLE_FILTERS_SELECTED:
      let tmp = JSON.parse(JSON.stringify(state.selectedFilter));
      tmp[action.index].isActive = !tmp[action.index].isActive;
        return {...state, selectedFilter: tmp}
      break;
    default:
    return {...state};

  }
}

export default reducer;
